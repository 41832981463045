import React, { useEffect, useState } from 'react';
import './ReportGen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFlask, faDna, faBaby, faTooth, faDroplet, faEllipsisV, faLayerGroup,
  faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons';
import KoreanFlag from '../flags/1f1f0-1f1f7.svg';
import TurkishFlag from '../flags/1f1f9-1f1f7.svg';
import AmericanFlag from '../flags/1f1fa-1f1f8.svg';
import TempInputRow from './TempInputRow';
import RecentReports from './RecentReports';
import StorageInfo from './StorageInfo/StorageInfo';

function ReportGen({ user }) {
  const [items, setItems] = useState([]);
  const [inputRows, setInputRows] = useState([]);
  const [invalidRows, setInvalidRows] = useState(new Set());
  const [flashInvalidRows, setFlashInvalidRows] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      fetch('https://service3.szapfs.org/api/report_requests')
        .then((response) => response.json())
        .then((data) => {
          setItems(data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };

    fetchData();
    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);


// ReportGen.jsx

const addNewInputRow = () => {
  const newRow = {
    id: Date.now(),
    PatientFirstName: '',
    PatientLastName: '',
    ReportType: '',
    PatientDateOfBirth: '',
    PatientGender: '',
    Preset: '',
    Details: '',
    Company: '', // Add Company field
    Language: '', // Add Language field
    Newborn: {}, // Correct key
  };
  setInputRows([...inputRows, newRow]);
};


const setNewReport = (id, updatedReport) => {
  setInputRows((prevInputRows) =>
    prevInputRows.map((row) =>
      row.id === id ? { ...row, ...updatedReport } : row
    )
  );
};


  const validateReport = (report) => {
    return (
      report.Preset &&
      report.isValidId && // Now we can access isValidId from the report object
      report.ReportType &&
      report.Company &&
      report.Language
    );
  };

  const handleRunQueue = () => {
    const newInvalidRows = new Set();
    const validReports = [];
  
    console.log("Starting to validate input rows...");
  
    inputRows.forEach((report, index) => {
      if (!validateReport(report)) {
        console.log(`Row ${index + 1} is invalid:`, report);
        newInvalidRows.add(report.id);
      } else {
        console.log(`Row ${index + 1} is valid:`, report);
        validReports.push(report);
      }
    });
  
    setInvalidRows(newInvalidRows);
  
    console.log("Invalid rows:", Array.from(newInvalidRows));
    console.log("Valid reports to process:", validReports);
  
    // Always flash invalid rows, even if some reports are valid
    if (newInvalidRows.size > 0) {
      setFlashInvalidRows(true);
      setTimeout(() => setFlashInvalidRows(false), 600); // Reduced to 600ms for snappier feedback
    }
  
    // Process only valid reports
    const languageMap = {
      Russian: 'RUS',
      Japanese: 'JPN',
      Korean: 'KOR',
      English: 'ENG',
      Turkish: 'TUR',
      Arabic: 'ARB',
      Spanish: 'SPA',
      Chinese: 'CHN',
    };
  
    console.log("Starting to process valid reports...");
  
    const postReports = validReports.map((report, index) => {
      const truncatedLanguage = languageMap[report.Language] || report.Language;
    
      const processedReport = {
        ...report,
        ReportType: `${report.ReportType} (${truncatedLanguage})`,
        Details: report.Newborn || {}, // Use Newborn JSON object directly in Details
        Company: report.Company, // Include Company
        Language: report.Language, // Include Language
      };
    
      // Clean up the report object before sending
      delete processedReport.isValidId; // Remove the validation state
      delete processedReport.Newborn; // Remove Newborn field after appending
    
      console.log(`Processed report ${index + 1}:`, processedReport);
    
      return fetch('https://service3.szapfs.org/api/reportinputreq', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(processedReport),
      });
    });
    
  
    Promise.all(postReports)
      .then((responses) => {
        console.log("All requests completed. Processing responses...");
        return Promise.all(responses.map((res) => res.json()));
      })
      .then((results) => {
        console.log("Responses received:", results);
        // Remove only the valid reports that were processed
        setInputRows(inputRows.filter(row => !validateReport(row)));
        console.log("Updated input rows:", inputRows);
      })
      .catch((error) => {
        console.error('Error running queue:', error);
      });
  };
  

  const handleDeleteReport = (id) => {
    setInputRows(inputRows.filter((row) => row.id !== id));
    const newInvalidRows = new Set(invalidRows);
    newInvalidRows.delete(id);
    setInvalidRows(newInvalidRows);
  };

  const isRunButtonEnabled = inputRows.length > 0;

  return (
    <div className="DRH">
      <div className="DRHL">
        <div className="DRHLTOP">
          <div className="DRHLTOPBOX">
            <div className="DRHLTOPBOXL">
              <input type="text" placeholder="Search" />
            </div>
            <div className="DRHLTOPBOXR">▼ Filter</div>
          </div>
        </div>
        <div className="queue-section-title">
        <div>

          <div className="queue1">
            Queue
            <div
  className={`run-btn ${!isRunButtonEnabled ? 'disabled' : ''}`}
  onClick={isRunButtonEnabled ? handleRunQueue : undefined}
>
  Run
  <FontAwesomeIcon icon={faChevronCircleRight} className="iconrun" />

</div>
</div>

          </div>
          <div className="queue-actions">
            <div className="addjr" onClick={addNewInputRow}>
              <div className="addjr1">
                <FontAwesomeIcon icon={faLayerGroup} />
              </div>
              <button className="add-job-btn">Add Report</button>
            </div>
          </div>
        </div>
        <div className="queue-section">
        {inputRows.length > 0 ? (
        inputRows.map((report) => (
          <TempInputRow
            key={report.id}
            report={report}
            setNewReport={setNewReport}
            handleDeleteReport={handleDeleteReport}
            isInvalid={invalidRows.has(report.id)}
            flash={flashInvalidRows && invalidRows.has(report.id)}
          />
        ))
      ) : (
        <div className="no-reports-placeholder">No pending reports</div>
      )}
        </div>
        <div className="recents-section-title">Files</div>
        <div className="recents-section">
          <RecentReports items={items} />
        </div>
      </div>
      <div className="DRHR">
        Storage Info Coming Soon 
      </div>
    </div>
  );
}

export default ReportGen;
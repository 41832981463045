import React, { useState, useEffect } from 'react';
import GeneriskproModal from './GRModal';
import { usePlatformAccount } from '../usePlatformAccount';
import './GRLanding.css';
import logo from '../../2222.png';
import CookieConsent from './CookieConsent';
import GRGenomicData from './GRGenomicData';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faChartLine, faSignOutAlt, faBell, faUserPlus, faBars, faDna, faSoap, faHandsBubbles, faHouseFloodWater, faWater, faMicroscope, faFilePdf, faCloud, faCloudUpload, faFilePowerpoint, faFile, faFileAlt, faCloudUploadAlt, faTable } from '@fortawesome/free-solid-svg-icons';
import Discoveromics from '../../discoveromics/Discoveromics.js';

const API_BASE = 'https://service8.szapfs.org';
const DEFAULT_ACCOUNT_ID = '26962f28-2907-40ea-a91b-695d479f8798';

const GeneriskDashboard = () => {
    const { platformAccount, isLoading: accountLoading, login, signup, logout } = usePlatformAccount();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [error, setError] = useState('');
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSignup, setIsSignup] = useState(false);
    const [reportUrl, setReportUrl] = useState(null);
    const [isFetchingReport, setIsFetchingReport] = useState(false);
    const [reportStatuses, setReportStatuses] = useState({});
    const [jobStatuses, setJobStatuses] = useState({});
    const [viewMode, setViewMode] = useState('pdf'); // 'pdf', 'data', or 'genomics'
    const [reportData, setReportData] = useState({
        AdultVeryImportant: [],
        AdultImportant: [],
        AdultNoteworthy: [],
        CarrierImportant: [],
        CarrierNoteworthy: [],
        NewbornVeryImportant: [],
        NewbornImportant: [],
        NewbornNoteworthy: []
    });

    useEffect(() => {
        fetchJobs();
        const interval = setInterval(fetchJobs, 1000);
        return () => clearInterval(interval);
    }, [platformAccount]); // Keep platformAccount dependency to update on login changes

    useEffect(() => {
        // Only check report_requests table for jobs in 'Generating' status
        const checkReportStatuses = async () => {
            for (const job of jobs) {
                if (job.status === 'Generating') {
                    try {
                        const response = await fetch(`https://service8.szapfs.org/api/generiskpro/report-status/${job.uuid}`);
                        const data = await response.json();
                        setReportStatuses(prev => ({
                            ...prev,
                            [job.uuid]: { status: data.status, reportLink: data.reportLink }
                        }));
                    } catch (error) {
                        console.error('Error fetching report status:', error);
                    }
                }
            }
        };

        if (jobs.some(job => job.status === 'Generating')) {
            const interval = setInterval(checkReportStatuses, 1000);
            return () => clearInterval(interval);
        }
    }, [jobs]);

    useEffect(() => {
        // Fetch status for all processed jobs
        jobs.forEach(job => {
            if (job.status === 'processed') {
                fetchJobStatus(job.uuid);
            }
        });

        const interval = setInterval(() => {
            jobs.forEach(job => {
                if (job.status === 'processed') {
                    fetchJobStatus(job.uuid);
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [jobs]);

    const getJobStatus = (job) => {
        if (job.status === 'Generating') {
            const reportStatus = reportStatuses[job.uuid];
            if (!reportStatus) return 'Processing';
            return reportStatus.status;
        }
        if (job.status === 'uploaded') return 'Queued';
        if (job.status === 'processing') return 'Processing';
        if (job.status === 'processed') return 'Processing';
        return job.status;
    };

    const getStatusClass = (status) => {
        switch (status.toLowerCase()) {
            case 'queued':
                return 'queued';
            case 'processing':
                return 'processing';
            case 'done':
                return 'done';
            default:
                return status.toLowerCase();
        }
    };

    const isJobReady = (job) => {
        if (job.status === 'Generating') {
            return reportStatuses[job.uuid]?.status === 'Done';
        }
        return false;
    };

    const handleJobClick = (job) => {
        if (!isJobReady(job)) return;
        setSelectedJob(job);
    };

    useEffect(() => {
        // When selectedJob changes, fetch report if needed
        if (selectedJob?.status === 'Generating') {
            const fetchReport = async () => {
                try {
                    const response = await fetch(`https://service8.szapfs.org/api/generiskpro/report-status/${selectedJob.uuid}`);
                    const data = await response.json();
                    if (data.status === 'Done' && data.reportLink) {
                        setReportUrl(data.reportLink);
                        // Also fetch the genomic data
                        const genomicResponse = await fetch(`https://service8.szapfs.org/api/generiskpro/genomic-data/${selectedJob.uuid}`);
                        const genomicData = await genomicResponse.json();
                        if (genomicData) {
                            setReportData({
                                AdultVeryImportant: genomicData.AdultVeryImportant || [],
                                AdultImportant: genomicData.AdultImportant || [],
                                AdultNoteworthy: genomicData.AdultNoteworthy || [],
                                CarrierImportant: genomicData.CarrierImportant || [],
                                CarrierNoteworthy: genomicData.CarrierNoteworthy || [],
                                NewbornVeryImportant: genomicData.NewbornVeryImportant || [],
                                NewbornImportant: genomicData.NewbornImportant || [],
                                NewbornNoteworthy: genomicData.NewbornNoteworthy || []
                            });
                        }
                    }
                } catch (error) {
                    console.error('Error fetching report:', error);
                }
            };
            fetchReport();
        } else {
            setReportUrl(null);
            // Reset report data when no job is selected
            setReportData({
                AdultVeryImportant: [],
                AdultImportant: [],
                AdultNoteworthy: [],
                CarrierImportant: [],
                CarrierNoteworthy: [],
                NewbornVeryImportant: [],
                NewbornImportant: [],
                NewbornNoteworthy: []
            });
        }
    }, [selectedJob]);

    const fetchJobStatus = async (uuid) => {
        try {
            const response = await fetch(
                `${API_BASE}/api/oncology/status?uuid=${uuid}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = await response.json();
            setJobStatuses(prev => ({
                ...prev,
                [uuid]: data.status
            }));

            // If this is the selected job and status just changed to Done, fetch report
            if (selectedJob?.uuid === uuid && data.status === 'Done') {
                fetchReportUrl();
            }
        } catch (error) {
            console.error('Error fetching job status:', error);
        }
    };

    const fetchJobs = async () => {
        try {
            // Use default account ID only if not logged in
            const accountId = platformAccount?.id || DEFAULT_ACCOUNT_ID;
            
            const response = await fetch(
                `https://service8.szapfs.org/api/generiskpro/processes/${accountId}`
            );
            if (response.ok) {
                const data = await response.json();
                setJobs(data);
                // Update selected job if it exists in the new data
                if (selectedJob) {
                    const updatedJob = data.find(job => job.uuid === selectedJob.uuid);
                    if (updatedJob && updatedJob.status !== selectedJob.status) {
                        setSelectedJob(updatedJob);
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setError('Failed to load jobs');
        }
    };

    useEffect(() => {
        setReportUrl(null);
        setIsFetchingReport(false);
        if (selectedJob?.status === 'processed') {
            // Initial fetch
            fetchReportUrl();
            // Also check status in case it's already Done
            fetchJobStatus(selectedJob.uuid);
            
            const interval = setInterval(() => {
                fetchJobStatus(selectedJob.uuid);
            }, 1000);
            
            return () => {
                clearInterval(interval);
                setReportUrl(null);
                setIsFetchingReport(false);
            };
        }
    }, [selectedJob?.uuid]);

    const fetchReportUrl = async () => {
        if (!selectedJob?.uuid || isFetchingReport) return;
        
        try {
            setIsFetchingReport(true);
            const response = await fetch(
                `${API_BASE}/api/oncology/report?uuid=${selectedJob.uuid}`
            );
            if (response.ok) {
                const data = await response.json();
                if (data.reportUrl) {
                    setReportUrl(data.reportUrl);
                }
            }
        } catch (error) {
            console.error('Error fetching report URL:', error);
        } finally {
            setIsFetchingReport(false);
        }
    };

    const handleUploadComplete = async (newJob) => {
        setJobs(prevJobs => [newJob, ...prevJobs]);
        setSelectedJob(null); // Clear selection after upload
        await fetchJobs();
    };

    const handleLogout = async () => {
        await logout();
        setShowUserMenu(false);
        setJobs([]);
        setSelectedJob(null);
        setReportUrl(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear any previous errors
        try {
            if (isSignup) {
                await signup(username, password);
            } else {
                await login(username, password);
            }
            setShowLoginForm(false);
            setUsername('');
            setPassword('');
            setIsSignup(false);
        } catch (err) {
            console.error(isSignup ? 'Signup error:' : 'Login error:', err);
            setError(err.message || 'An error occurred during login');
        }
    };

    const formatDate = (timestamp) => {
        return new Date(timestamp).toLocaleString();
    };

    const renderJobs = () => {
        if (!jobs.length) {
            return (
                <div className="Generiskpro-empty-state">
                    <p>Upload a new analysis to get started</p>
                </div>
            );
        }

        return jobs.map((job) => {
            const isReady = isJobReady(job);
            return (
                <div
                    key={job.uuid}
                    className={`Generiskpro-job-item ${isReady ? 'ready' : ''} ${selectedJob?.uuid === job.uuid ? 'selected' : ''}`}
                    onClick={() => handleJobClick(job)}
                >
                    <div className="Generiskpro-job-header">
                        <span className="Generiskpro-job-name">
                            {job.original_filename}
                        </span>
                        <span className={`Generiskpro-job-status ${getStatusClass(getJobStatus(job))}`}>
                            {getJobStatus(job)}
                        </span>
                    </div>
                    <div className="Generiskpro-job-details">
                        <span>{formatDate(job.upload_time)}</span>
                    </div>
                </div>
            );
        });
    };

    const renderPdfViewer = () => {
        if (!reportUrl) {
            return null;
        }

        // Modify URL to add preview parameter
        const previewUrl = reportUrl.replace('/view', '/preview');

        // Use a key to force iframe refresh when URL changes
        return (
            <div className="Generiskpro-pdf-viewer">
                <div className="Generiskpro-loader" />
                <iframe
                    key={previewUrl}
                    src={previewUrl}
                />
            </div>
        );
    };

    const renderDataView = () => {
        if (!selectedJob) return null;

        const {
            AdultVeryImportant,
            AdultImportant,
            AdultNoteworthy,
            CarrierImportant,
            CarrierNoteworthy,
            NewbornVeryImportant,
            NewbornImportant,
            NewbornNoteworthy
        } = reportData;

        return (
            <div className="Generiskpro-data-view">
                <div className="Generiskpro-data-section">
                    <h3>Adult Health Results</h3>
                    {renderDataTable("Very Important", AdultVeryImportant)}
                    {renderDataTable("Important", AdultImportant)}
                    {renderDataTable("Noteworthy", AdultNoteworthy)}
                </div>

                <div className="Generiskpro-data-section">
                    <h3>Carrier Status</h3>
                    {renderDataTable("Important", CarrierImportant)}
                    {renderDataTable("Noteworthy", CarrierNoteworthy)}
                </div>

                <div className="Generiskpro-data-section">
                    <h3>Newborn Screening</h3>
                    {renderDataTable("Very Important", NewbornVeryImportant)}
                    {renderDataTable("Important", NewbornImportant)}
                    {renderDataTable("Noteworthy", NewbornNoteworthy)}
                </div>
            </div>
        );
    };

    const renderDataTable = (severity, data) => {
        if (!data || data.length === 0) return null;

        return (
            <div className={`Generiskpro-data-table ${severity.toLowerCase().replace(' ', '-')}`}>
                <h4>{severity} ({data.length})</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Disease</th>
                            <th>Gene</th>
                            <th>Genotype</th>
                            <th>Inheritance</th>
                            <th>Impact</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.Disease}</td>
                                <td>{item.Genes}</td>
                                <td>{item.Genotype}</td>
                                <td>{item.Inheritances}</td>
                                <td>{item.IMPACT}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderMainContent = () => {
        if (viewMode === 'genomics') {
            return <Discoveromics />;
        }

        if (!selectedJob) {
            return (
                <div className="Generiskpro-empty-state">
                    <h2>Select a job to view details</h2>
                    <p>Or upload a new analysis to get started</p>
                </div>
            );
        }

        if (!isJobReady(selectedJob)) {
            return (
                <div className="Generiskpro-processing-state">
                    <h2>Report Being Generated</h2>
                    <p>Please wait while we process your analysis...</p>
                </div>
            );
        }

        return (
            <>
                {viewMode === 'pdf' ? renderPdfViewer() : <GRGenomicData reportId={selectedJob.uuid} />}
                {reportUrl && (
                    <div className="Generiskpro-view-toggle fade-in">
                        <div 
                            className={`Generiskpro-view-option ${viewMode === 'pdf' ? 'active' : ''}`}
                            onClick={() => setViewMode('pdf')}
                        >
                            <FontAwesomeIcon icon={faFilePdf} />
                            <span>PDF View</span>
                        </div>
                        <div 
                            className={`Generiskpro-view-option ${viewMode === 'data' ? 'active' : ''}`}
                            onClick={() => setViewMode('data')}
                        >
                            <FontAwesomeIcon icon={faTable} />
                            <span>Raw Data</span>
                        </div>
                    </div>
                )}
            </>
        );
    };

    useEffect(() => {
        if (selectedJob) {
            setViewMode('pdf');
        }
    }, [selectedJob]);

    return (
        <div className="Generiskpro-dashboard">
            <div className="Generiskpro-sidebar">
                <div className="Generiskpro-logo">
                    <img src={logo} alt="Oncorisk Logo" />
                </div>
                <div className="Generiskpro-controls">
                    {platformAccount ? (
                        <div className="Generiskpro-user-info">
                            <button 
                                className="Generiskpro-user-button"
                                onClick={() => setShowUserMenu(!showUserMenu)}
                            >
                                <div className="Generiskpro-profile-circle">
                                    {platformAccount.username[0].toUpperCase()}
                                </div>
                                <span>{platformAccount.username.charAt(0).toUpperCase() + platformAccount.username.slice(1)}</span>






                            </button>
                            {showUserMenu && (
                                <div className="Generiskpro-user-menu">
                                    <button 
                                        className="Generiskpro-menu-item"
                                        onClick={handleLogout}
                                    >
                                        Log Out
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div 
                            className="Generiskpro-login-btn"
                            onClick={() => setShowLoginForm(true)}
                        >
                            Log In
                            
                        </div>
                    )}
                                    <div className="Generiskpro-controls1">

                    <div 
                        className="Generiskpro-upload-btn"
                        onClick={() => platformAccount ? setIsModalOpen(true) : setShowLoginForm(true)}
                    >
                        <FontAwesomeIcon icon={faCloudUploadAlt} className="outline-icon" />

                        Upload
                    </div>
                    <div 
                        className="Generiskpro-upload-btn1"
                        onClick={() => {
                            setViewMode('genomics');
                            setSelectedJob(null); 
                        }}
                    >
                        <FontAwesomeIcon icon={faMicroscope} />
                        Allele Frequency Tool
                    </div>
                    </div>

                </div>
                <div className="Generiskpro-jobs-list">
                    {renderJobs()}
                </div>
            </div>

            <div className={`Generiskpro-main-content ${viewMode === 'genomics' ? 'genomics-active' : ''}`}>
                {renderMainContent()}
            </div>

            {isModalOpen && (
                <GeneriskproModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onUploadComplete={handleUploadComplete}
                    platformAccount={platformAccount}
                />
            )}

            {showLoginForm && (
                <div className="Generiskpro-modal-overlay">
                    <div className="Generiskpro-modal">
                        <button 
                            className="Generiskpro-modal-close"
                            onClick={() => {
                                setShowLoginForm(false);
                                setIsSignup(false);
                            }}
                        >
                            ×
                        </button>
                        <div className="Generiskpro-modal-header">
                            <h2>{isSignup ? 'Sign Up' : 'Log In'}</h2>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="Generiskpro-form-group">
                                <label>Username</label>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="Generiskpro-form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            {error && (
                                <div className="Generiskpro-error-message">
                                    {error}
                                </div>
                            )}
                            <button type="submit" className="Generiskpro-submit-btn">
                                {isSignup ? 'Sign Up' : 'Log In'}
                            </button>
                            <div className="Generiskpro-auth-switch">
                                {isSignup ? (
                                    <p>
                                        Already have an account?{' '}
                                        <button 
                                            onClick={() => {
                                                setIsSignup(false);
                                                setUsername('');
                                                setPassword('');
                                                setError('');
                                            }}
                                        >
                                            Log In
                                        </button>
                                    </p>
                                ) : (
                                    <p>
                                        Don't have an account?{' '}
                                        <button 
                                            onClick={() => {
                                                setIsSignup(true);
                                                setUsername('');
                                                setPassword('');
                                                setError('');
                                            }}
                                        >
                                            Sign Up
                                        </button>
                                    </p>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <CookieConsent />
        </div>
    );
};

export default GeneriskDashboard;
import React from 'react';
import './LabMain.css';

function LabMain({ userId }) {
  return (
    <div className="LabMain">
     <div className="LabMainR"></div>

    <div className="LabMainL">
        <div className="LabMainL1">
            <div className="LabMainL1L"></div>
        </div>

        
        <div className="LabMainL2"></div>
    </div>
    </div>
  );
}
    
export default LabMain;

import React, { useState, useEffect } from 'react';
import './ProjectManager.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ProjectTile from './ProjectTile/ProjectTile';
import ProjectDetails from './ProjectDetails/ProjectDetails';
import UploadManager from './UploadManager/UploadManager';

function ProjectManager() {
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAddProject, setShowAddProject] = useState(false);
    const [newProjectName, setNewProjectName] = useState('');
    const [isCreating, setIsCreating] = useState(false);

    const fetchProjects = async () => {
        try {
            setLoading(true);
            const response = await fetch('https://service9.szapfs.org/parent-projects');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Ensure each project has a reports array
            const projectsWithReports = data.map(project => ({
                ...project,
                reports: project.reports || []
            })).sort((a, b) => a.id - b.id);
            setProjects(projectsWithReports);
            setError(null);
        } catch (err) {
            console.error('Error fetching projects:', err);
            setError('Failed to load projects. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleProjectClick = (projectId) => {
        setSelectedProjectId(projectId);
    };

    const handleBackClick = () => {
        setSelectedProjectId(null);
    };

    const handleAddProject = async () => {
        if (!newProjectName.trim()) return;

        try {
            setIsCreating(true);
            const response = await fetch('https://service9.szapfs.org/project', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newProjectName.trim() })
            });

            if (!response.ok) throw new Error('Failed to create project');

            // Refetch all projects to get fresh data
            await fetchProjects();
            setShowAddProject(false);
            setNewProjectName('');
        } catch (error) {
            console.error('Error creating project:', error);
            alert('Failed to create project. Please try again.');
        } finally {
            setIsCreating(false);
        }
    };

    if (loading) {
        return (
            <div className="PMA-container">
                <div className="PMAP-container">
                    <div className="PMAP-loading">...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="PMA-container">
                <div className="PMAP-container">
                    <div className="PMAP-error">{error}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="PMA-container">
            <div className="PMAP-container">
                {selectedProjectId ? (
                    <ProjectDetails 
                        projectId={selectedProjectId} 
                        projectData={projects.find(p => p.id === selectedProjectId)}
                        onBackClick={handleBackClick} 
                    />
                ) : (
                    <>
                        <div className="PMAP-container-U">
                            <div>
                                <input 
                                    className='PMP-search' 
                                    type="text" 
                                    placeholder="Search projects..." 
                                />
                            </div>
                            <FontAwesomeIcon 
                                icon={faPlusCircle}
                                className="PMP-add-project"
                                onClick={() => setShowAddProject(true)}
                            />
                        </div>
                        <div className="PMAP-container-D">
                            <div className="PMAP-grid">
                                {showAddProject && (
                                    <div className="PMAP-project PMAP-temp-project">
                                        <input
                                            type="text"
                                            className="PMAP-project-name-input"
                                            placeholder="Project Name"
                                            value={newProjectName}
                                            onChange={(e) => setNewProjectName(e.target.value)}
                                            onKeyPress={(e) => e.key === 'Enter' && handleAddProject()}
                                            autoFocus
                                        />
                                        <div className="PMAP-temp-project-buttons">
                                            <button 
                                                className="PMAP-button"
                                                onClick={handleAddProject}
                                                disabled={isCreating || !newProjectName.trim()}
                                            >
                                                {isCreating ? 'Creating...' : 'Create'}
                                            </button>
                                            <button 
                                                className="PMAP-cancel-button"
                                                onClick={() => {
                                                    setShowAddProject(false);
                                                    setNewProjectName('');
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {projects.map((project) => (
                                    <ProjectTile 
                                        key={project.id} 
                                        project={project} 
                                        onProjectClick={() => handleProjectClick(project.id)}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
            {!selectedProjectId && (
                <div className="PMAPR-container">
                    <UploadManager />
                </div>
            )}
        </div>
    );
}

export default ProjectManager;
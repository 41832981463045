import React, { useState, useEffect } from 'react';
import './UploadManager.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadModal from './UploadModal';
import {
    faSearch,
    faCloudArrowUp,
    faCircleCheck,
    faCircleXmark,
    faCirclePause,
    faSpinner,
    faDna,
    faMicroscope,
    faVial,
    faChartLine,
    faAtom,
    faSyringe,
    faDroplet,
    faBacteria,
    faFlask,
    faVialVirus,
    faCrosshairs
} from '@fortawesome/free-solid-svg-icons';

const analysisTypes = {
    'WG': { name: 'WGS', description: 'Whole Genome Sequencing', icon: faDna },
    'WE': { name: 'WES', description: 'Whole Exome Sequencing', icon: faMicroscope },
    'MP': { name: 'Methylome Profile', description: 'Methylome Profile', icon: faVial },
    'WT': { name: 'WTS', description: 'Whole Transcriptome Sequencing', icon: faChartLine },
    'PR': { name: 'Proteomics', description: 'Proteomics Analysis', icon: faAtom },
    'MB': { name: 'Metabolomics', description: 'Metabolomics Analysis', icon: faSyringe },
    'LD': { name: 'Lipidomics', description: 'Lipidomics Analysis', icon: faDroplet },
    'MG': { name: 'METAGENOME', description: 'Metagenome Analysis', icon: faBacteria },
    'SR': { name: '16S rRNA', description: '16S rRNA Sequencing', icon: faFlask },
    'CL': { name: 'Cancer Liquid', description: 'Cancer Liquid Biopsy', icon: faVialVirus },
    'CS': { name: 'Cancer Solid Tumor', description: 'Cancer Solid Tumor Analysis', icon: faCrosshairs }
};

const sampleTypes = {
    'NT': 'Normal Tissue',
    'TT': 'Tumour Tissue',
    'WB': 'Whole Blood',
    'PL': 'Plasma',
    'SE': 'Serum',
    'SA': 'Saliva',
    'UR': 'Urine',
    'ST': 'Stool',
    'BS': 'Buccal Swab',
    'SS': 'Skin Swab',
    'VS': 'Vaginal Swab',
    'CS': 'Cerebro Spinal Fluid',
    'BA': 'Bacteria',
    'PT': 'Plant'
};

const statusConfig = {
    'Failed': { color: '#dc3545', icon: faCircleXmark, spin: false },
    'Completed': { color: '#28a745', icon: faCircleCheck, spin: false },
    'Queued': { color: '#6c757d', icon: faCirclePause, spin: false },
    'Processing': { color: '#ffc107', icon: faSpinner, spin: true },
    'Uploading': { color: '#007bff', icon: faSpinner, spin: true }
};

function UploadManager() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [uploads, setUploads] = useState([]);

    useEffect(() => {
        const fetchUploads = async () => {
            try {
                const response = await fetch('https://service9.szapfs.org/uploads');
                if (!response.ok) throw new Error('Failed to fetch uploads');
                const data = await response.json();
                setUploads(data);
            } catch (error) {
                console.error('Error fetching uploads:', error);
            }
        };

        // Initial fetch
        fetchUploads();

        // Set up interval for periodic updates
        const intervalId = setInterval(fetchUploads, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const handleUploadClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const getFileId = (upload) => {
        const visitNumberPart = upload.analysisCode === 'MG' ? `_${upload.visitNumber}` : '';
        return `${upload.sampleCode}${upload.analysisCode}_${upload.patientId}${visitNumberPart}`;
    };

    const sortUploads = (uploads) => {
        const statusOrder = {
            'Processing': 0,
            'Uploading': 1,
            'Queued': 2,
            'Completed': 3
        };
        
        return [...uploads].sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
    };

    const getStatusOpacity = (status) => {
        return status === 'Processing' || status === 'Uploading' ? 1 : 0.7;
    };

    return (
        <div className="UPM-container">
            <div className="UPM-header">
                <button className="UPM-upload-button" 
                onClick={handleUploadClick}>
                <FontAwesomeIcon icon={faCloudArrowUp} />
                </button>
                <div className="UPM-search-box">
                    <FontAwesomeIcon icon={faSearch} className="UPM-search-icon" />
                    <input
                        type="text"
                        placeholder="Search uploads..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <div className="UPM-divider" />

            <div className="UPM-content">
                {sortUploads(uploads).map((upload) => (
                    <div 
                        key={upload.id} 
                        className="UPM-upload-tile"
                        style={{ opacity: getStatusOpacity(upload.status) }}
                    >
                        <div className="UPM-i1">
                            <div className="UPM-emoji-circle">
                                <FontAwesomeIcon icon={analysisTypes[upload.analysisCode].icon} />
                            </div>
                        </div>
                        <div className="UPM-tile-content">
                            <div className="UPM-header-content">
                                <div className="UPM-header-top">
                                    <span className="UPM-test-name">
                                        {analysisTypes[upload.analysisCode].description}
                                    </span>
                                    <span className="UPM-test-name1">
                                        {sampleTypes[upload.sampleCode]}
                                    </span>
                                </div>
                                <div className="UPM-header-bottom">
                                    <span>{upload.patientId}</span>
                                </div>
                            </div>
                            <div className="split"></div>
                            <div className="UPM-progress-section">
                                <div className="UPM-progress-bar">
                                    <div 
                                        className="UPM-progress-fill" 
                                        style={{ width: `${upload.progress}%`}}
                                    >
                                        <span className="UPM-progress-text">{upload.progress}%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="UPM-file-info">
                                <span className="UPM-original-filename">  {new Date(upload.uploadTime).toLocaleString('en-US', {
                                                                            year: '2-digit',
                                                                            month: '2-digit',
                                                                            day: '2-digit',
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                            hour12: true,
                                                                        })}</span>
                                <span className="UPM-original-filename">{getFileId(upload)}</span>
                            </div>
                        </div>
                        <div className="UPM-i2">
                            <div 
                                className="UPM-status-container" 
                                style={{ color: statusConfig[upload.status].color, borderColor: statusConfig[upload.status].color }}
                            >
                                <FontAwesomeIcon 
                                    icon={statusConfig[upload.status].icon}
                                    spin={statusConfig[upload.status].spin}
                                />
                                <span className="UPM-file-status">{upload.status}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <UploadModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                analysisTypes={analysisTypes}
                sampleTypes={sampleTypes}
            />
        </div>
    );
}

export default UploadManager;

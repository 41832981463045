import { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';

const API_BASE = 'https://service7.szapfs.org';

export const usePlatformAccount = () => {
    const [platformAccount, setPlatformAccount] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const checkSession = useCallback(async () => {
        const account = Cookies.get('platform_account');
        if (account) {
            setPlatformAccount(JSON.parse(account));
            setIsLoading(false);
            return true;
        }
        setIsLoading(false);
        return false;
    }, []);

    useEffect(() => {
        checkSession();
    }, [checkSession]);

    const login = async (username, password) => {
        setIsLoading(true);
        try {
            console.log('Attempting login...');
            const response = await fetch(`${API_BASE}/api/platform/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include'
            });
            
            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.error || 'Login failed');
            }

            console.log('Login successful:', data);
            
            // Store in cookies
            Cookies.set('platform_account', JSON.stringify(data), { expires: 7 });
            setPlatformAccount(data);
            
            return data;
        } catch (err) {
            console.error('Login error:', err);
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const signup = async (username, password) => {
        setIsLoading(true);
        try {
            console.log('Attempting signup...');
            const response = await fetch(`${API_BASE}/api/platform/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include'
            });

            const data = await response.json();
            
            if (!response.ok) {
                // The backend returns 400 for username already exists
                if (data.error === 'Username already exists') {
                    throw new Error('This username is already taken. Please choose a different one.');
                }
                throw new Error(data.error || 'Signup failed');
            }

            console.log('Signup successful:', data);
            // After successful signup, log in to get the session
            await login(username, password);
            return data;
        } catch (err) {
            console.error('Signup error:', err);
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const logout = async () => {
        try {
            // Clear cookies first
            Cookies.remove('platform_account');
            setPlatformAccount(null);
            
            // Then notify backend
            await fetch(`${API_BASE}/api/platform/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (err) {
            console.error('Logout error:', err);
            // Even if backend logout fails, keep the frontend logged out
            setPlatformAccount(null);
        }
    };

    return {
        platformAccount,
        isLoading,
        error,
        login,
        signup,
        logout,
        checkSession
    };
};

import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './PatientMetadataEditor.css';

const GENDER_OPTIONS = ['MALE', 'FEMALE', 'OTHER'];
const ALCOHOL_OPTIONS = ['NONE', 'SOCIALLY', 'REGULARLY'];

function PatientMetadataEditor({ patient, onClose, onSave }) {
    const modalRef = useRef(null);
    const [formData, setFormData] = useState({
        name: patient.name || '',
        family_number: patient.family_number || '',
        date_of_birth: patient.date_of_birth || '',
        gender: patient.gender || '',
        chronic_disease: patient.chronic_disease || '',
        drug_consumption: patient.drug_consumption || '',
        smoking_pcs_per_day: patient.smoking_pcs_per_day || '',
        alcohol_usage: patient.alcohol_usage || '',
        height_cm: patient.height_cm || '',
        weight_kg: patient.weight_kg || '',
        phone: patient.phone || '',
        sample_acceptance_date: patient.sample_acceptance_date || '',
        sample_type: patient.sample_type || ''
    });

    const [isVisible, setIsVisible] = useState(false);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsVisible(true), 50);

        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleClose = () => {
        setIsFading(true);
        setTimeout(() => {
            onClose();
        }, 150);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        onSave(formData);
    };

    const handleChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    return (
        <div className={`PME-overlay ${isVisible ? 'visible' : ''}`}>
            <div 
                ref={modalRef}
                className={`PME-modal ${isFading ? 'PMEFadeaway' : ''} ${isVisible ? 'PMEFadein' : ''}`}
            >
                <div className="PME-header">
                    <h2>Edit Patient Information</h2>
                    <FontAwesomeIcon 
                        icon={faTimes} 
                        className="PME-close"
                        onClick={handleClose}
                    />
                </div>

                <form className="PME-form" onSubmit={handleSubmit}>
                    <div className="PME-form-grid">
                        <div className="PME-form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                value={formData.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                            />
                        </div>

                        <div className="PME-form-group">
                            <label>Family Number</label>
                            <input
                                type="text"
                                value={formData.family_number}
                                onChange={(e) => handleChange('family_number', e.target.value)}
                            />
                        </div>

                        <div className="PME-form-group">
                            <label>Date of Birth</label>
                            <input
                                type="text"
                                value={formData.date_of_birth}
                                onChange={(e) => handleChange('date_of_birth', e.target.value)}
                                placeholder="DD-MM-YYYY"
                            />
                        </div>

                        <div className="PME-form-group">
                            <label>Gender</label>
                            <select
                                value={formData.gender}
                                onChange={(e) => handleChange('gender', e.target.value)}
                            >
                                <option value="">Select Gender</option>
                                {GENDER_OPTIONS.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>

                        <div className="PME-form-group">
                            <label>Phone</label>
                            <input
                                type="text"
                                value={formData.phone}
                                onChange={(e) => handleChange('phone', e.target.value)}
                            />
                        </div>

                        <div className="PME-form-group">
                            <label>Height (cm)</label>
                            <input
                                type="number"
                                value={formData.height_cm}
                                onChange={(e) => handleChange('height_cm', e.target.value)}
                            />
                        </div>

                        <div className="PME-form-group">
                            <label>Weight (kg)</label>
                            <input
                                type="number"
                                value={formData.weight_kg}
                                onChange={(e) => handleChange('weight_kg', e.target.value)}
                            />
                        </div>

                        <div className="PME-form-group">
                            <label>Smoking (pcs/day)</label>
                            <input
                                type="number"
                                value={formData.smoking_pcs_per_day}
                                onChange={(e) => handleChange('smoking_pcs_per_day', e.target.value)}
                            />
                        </div>

                        <div className="PME-form-group">
                            <label>Alcohol Usage</label>
                            <select
                                value={formData.alcohol_usage}
                                onChange={(e) => handleChange('alcohol_usage', e.target.value)}
                            >
                                <option value="">Select Usage</option>
                                {ALCOHOL_OPTIONS.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>

                        <div className="PME-form-group full-width">
                            <label>Chronic Disease</label>
                            <textarea
                                value={formData.chronic_disease}
                                onChange={(e) => handleChange('chronic_disease', e.target.value)}
                                rows={2}
                            />
                        </div>

                        <div className="PME-form-group full-width">
                            <label>Drug Consumption</label>
                            <textarea
                                value={formData.drug_consumption}
                                onChange={(e) => handleChange('drug_consumption', e.target.value)}
                                rows={2}
                            />
                        </div>

                        <div className="PME-form-group">
                            <label>Sample Acceptance Date</label>
                            <input
                                type="text"
                                value={formData.sample_acceptance_date}
                                onChange={(e) => handleChange('sample_acceptance_date', e.target.value)}
                                placeholder="DD-MM-YYYY"
                            />
                        </div>

                        <div className="PME-form-group">
                            <label>Sample Type</label>
                            <input
                                type="text"
                                value={formData.sample_type}
                                onChange={(e) => handleChange('sample_type', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="PME-actions">
                        <button type="button" className="PME-button secondary" onClick={handleClose}>
                            Cancel
                        </button>
                        <button type="submit" className="PME-button primary">
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PatientMetadataEditor;

import React, { useState } from 'react';
import './OncologyModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDna, faVial, faFlask, faCloudUploadAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const OncologyModal = ({ isOpen, onClose, onUploadComplete, platformAccount }) => {
    const [files, setFiles] = useState({
        vcf: null,
        dna: null,
        rna: null
    });
    const [error, setError] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = (type, file) => {
        setFiles(prev => ({
            ...prev,
            [type]: file
        }));
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!files.vcf || !files.dna) {
            setError('VCF/JSON and DNA files are required');
            return;
        }

        setIsUploading(true);
        setError('');

        const formData = new FormData();
        formData.append('vcf', files.vcf);
        formData.append('dna', files.dna);
        if (files.rna) {
            formData.append('rna', files.rna);
        }
        formData.append('accountId', platformAccount.id);

        try {
            const response = await fetch('https://service7.szapfs.org/api/oncology/upload', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Upload failed');
            }

            onUploadComplete(data);
            onClose();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsUploading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="oncology-upload-modal-overlay">
            <div className="oncology-upload-modal">
                <button className="oncology-upload-modal-close" onClick={onClose}>×</button>
                <div className="oncology-upload-modal-header">
                    <h2>Upload Analysis Files</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="oncology-upload-options">
                        <div className="oncology-upload-wrapper">
                            <div className={`oncology-upload-option ${files.vcf ? 'has-file' : ''}`}>
                                <input
                                    type="file"
                                    accept=".vcf,.vcf.gz,.json,.json.gz"
                                    onChange={(e) => handleFileChange('vcf', e.target.files[0])}
                                    required
                                />
                                <div className="oncology-upload-header">
                                    <FontAwesomeIcon icon={faDna} className="oncology-upload-icon" />
                                    <h3 className="oncology-upload-title">VCF/JSON File</h3>
                                </div>
                                {files.vcf ? (
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} className="oncology-checkmark" />
                                        <div className="oncology-file-selected">{files.vcf.name}</div>
                                    </>
                                ) : (
                                    <div className="oncology-upload-cloud">
                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="oncology-upload-cloud-icon" />
                                        <p className="oncology-upload-text">Click or drag file to upload</p>
                                    </div>
                                )}
                            </div>
                            <div className="oncology-file-type">Required - .vcf, .json files</div>
                        </div>

                        <div className="oncology-upload-wrapper">
                            <div className={`oncology-upload-option ${files.dna ? 'has-file' : ''}`}>
                                <input
                                    type="file"
                                    accept=".tsv,.csv"
                                    onChange={(e) => handleFileChange('dna', e.target.files[0])}
                                    required
                                />
                                <div className="oncology-upload-header">
                                    <FontAwesomeIcon icon={faVial} className="oncology-upload-icon" />
                                    <h3 className="oncology-upload-title">DNA Combined Output</h3>
                                </div>
                                {files.dna ? (
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} className="oncology-checkmark" />
                                        <div className="oncology-file-selected">{files.dna.name}</div>
                                    </>
                                ) : (
                                    <div className="oncology-upload-cloud">
                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="oncology-upload-cloud-icon" />
                                        <p className="oncology-upload-text">Click or drag file to upload</p>
                                    </div>
                                )}
                            </div>
                            <div className="oncology-file-type">Required - .tsv, .csv files</div>
                        </div>

                        <div className="oncology-upload-wrapper">
                            <div className={`oncology-upload-option ${files.rna ? 'has-file' : ''}`}>
                                <input
                                    type="file"
                                    accept=".tsv,.csv"
                                    onChange={(e) => handleFileChange('rna', e.target.files[0])}
                                />
                                <div className="oncology-upload-header">
                                    <FontAwesomeIcon icon={faFlask} className="oncology-upload-icon" />
                                    <h3 className="oncology-upload-title">RNA Combined Output</h3>
                                </div>
                                {files.rna ? (
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} className="oncology-checkmark" />
                                        <div className="oncology-file-selected">{files.rna.name}</div>
                                    </>
                                ) : (
                                    <div className="oncology-upload-cloud">
                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="oncology-upload-cloud-icon" />
                                        <p className="oncology-upload-text">Click or drag file to upload</p>
                                    </div>
                                )}
                            </div>
                            <div className="oncology-file-type">Optional - .tsv, .csv files</div>
                        </div>
                    </div>

                    {error && <div className="oncology-error-message">{error}</div>}

                    <button 
                        type="submit" 
                        className="oncology-submit-btn"
                        disabled={isUploading || !files.vcf || !files.dna}
                    >
                        {isUploading ? 'Uploading...' : 'Submit Analysis'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default OncologyModal;

// RecentReports.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFlask, 
  faDna, 
  faBaby, 
  faTooth, 
  faDroplet, 
  faEllipsisV, 
  faCircleDot,
  faCircleNotch,
  faCircleRight,
  faClock,
  faSpinner,
  faTimes,
  faCancel,
  faBowlFood,
  faBacteria,
  faHandsBubbles,
  faProcedures,
  faChartGantt,
  faOilCan,
  faVial, 
  faThunderstorm,
  faBoltLightning,
  faCapsules,
  faCircleNodes,
  faVials,
  faCarriageBaby,
  faUserCheck,
  faDashboard,
  faJugDetergent,
  faDesktopAlt,
  faBookMedical,
  faLink,
  faClinicMedical,
  faSearch,
  faStethoscope
} from '@fortawesome/free-solid-svg-icons';
import KoreanFlag from '../flags/1f1f0-1f1f7.svg';
import TurkishFlag from '../flags/1f1f9-1f1f7.svg';
import AmericanFlag from '../flags/1f1fa-1f1f8.svg';
import './ReportGen.css'; // Ensure your styling is included
import './RecentReports.css'; // Ensure your styling is included

const RecentReports = ({ items }) => {
  return (
    <>
{items.map((item, index) => (
  <div
    key={index}
    className={`recent-item ${item.Status === 'Done' ? '' : 'not-done'}`}
>



{item.Status === 'Pending' && (
        <div className="ri-status ri-status-pending">
            <FontAwesomeIcon icon={faClock} />
            {item.Status}
        </div>
    )}
    {item.Status === 'Processing' && (
        <div className="ri-status ri-status-processing">
    <FontAwesomeIcon icon={faSpinner} className="spin-animation" />
    {item.Status}
        </div>
    )}
    {item.Status === 'Failed' && (
        <div className="ri-status ri-status-failed">
            <FontAwesomeIcon icon={faCancel} />
            {item.Status}
        </div>
    )}
        <div className="ri1">
            <div className="ri1c"></div>
        </div>
        <div className="ri2">
            <div className="ri2in">
                {item.ReportType?.toLowerCase().includes('oncology') && (
                    <FontAwesomeIcon icon={faFlask} />
                )}
                {item.ReportType?.toLowerCase().includes('genome') && (
                    <FontAwesomeIcon icon={faDna} />
                )}
                {item.ReportType?.toLowerCase().includes('genomic') && (
                    <FontAwesomeIcon icon={faDna} />
                )}
                {item.ReportType?.toLowerCase().includes('newborn') && (
                    <FontAwesomeIcon icon={faBaby} />
                )}
                {item.ReportType?.toLowerCase().includes('oral') && (
                    <FontAwesomeIcon icon={faTooth} />
                )}
                {item.ReportType?.toLowerCase().includes('gut') && (
                    <FontAwesomeIcon icon={faBacteria} />
                )}
                {item.ReportType?.toLowerCase().includes('lipidome') && (
                    <FontAwesomeIcon icon={faFlask} />
                )}
                {item.ReportType?.toLowerCase().includes('transcriptome') && (
                    <FontAwesomeIcon icon={faChartGantt} />
                )}
                {item.ReportType?.toLowerCase().includes('urine') && (
                    <FontAwesomeIcon icon={faDroplet} />
                )}
                {item.ReportType?.toLowerCase().includes('carrier') && (
                    <FontAwesomeIcon icon={faUserCheck} />
                )}
                                {item.ReportType?.toLowerCase().includes('proteomics') && (
                    <FontAwesomeIcon icon={faCircleNodes} />
                )}
                {item.ReportType?.toLowerCase().includes('plasma') && (
                    <FontAwesomeIcon icon={faVial} />
                )}
                {item.ReportType?.toLowerCase().includes('exome') && (
                    <FontAwesomeIcon icon={faStethoscope} />
                )}
                {!item.ReportType &&
                    `${item.PatientFirstName?.[0]?.toUpperCase() || ''}${
                        item.PatientLastName?.[0]?.toUpperCase() || ''
                    }`}
            </div>
        </div>
        <div className="ri3"
            onClick={() => item.ReportLink && window.open(item.ReportLink, '_blank')}

        >
            <div className="ri31">
                <div className="ri31T">
                {(item.PatientFirstName || item.PatientLastName) &&
    `${
        (item.PatientFirstName || '').length + (item.PatientLastName || '').length > 25
            ? `${(item.PatientFirstName || '')} ${(item.PatientLastName || '')}`.slice(0, 22) + '...'
            : `${item.PatientFirstName || ''} ${item.PatientLastName || ''}`
    }`
}

                </div>
                <div className="ri31B">
                    {item.ReportType && item.ReportType.match(/\((.*?)\)/)
                        ? item.ReportType.match(/\((.*?)\)/)[1] === 'KOR'
                            ? <img className="flag-image1" src={KoreanFlag} alt="Korean Flag" />
                            : item.ReportType.match(/\((.*?)\)/)[1] === 'TUR'
                                ? <img className="flag-image1" src={TurkishFlag} alt="Turkish Flag" />
                                : <img className="flag-image1" src={AmericanFlag} alt="American Flag" />
                        : <div></div>}
                    {item.ReportType
    ? item.ReportType.replace(/\(.*?\)/, '').trim() + ' '
    : ''}    
• {(item.Details || '').match(/!(.*?)!/)?.[1] || item.Company || ''}


                </div>
            </div>
            <div className="ri311">{item.SampleID || ''}</div>
            <div className="ri32">
                <FontAwesomeIcon icon={faEllipsisV} className="outline-icon" />
            </div>
        </div>
    </div>
))}


    </>
  );
};

export default RecentReports;

import React, { useState, useEffect, useRef } from 'react';
import './GRGenomicData.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faSpinner, faPills, faDna, faEyeSlash, faEye, faFilter, faCirclePlus, faFileCsv, faSave, faPen, faArrowLeft, faTrash, faCheck, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import { useMemo } from 'react';
import GenomicRegeneration from './GenomicRegeneration';

const GRGenomicData = ({ reportId, user }) => {
    const [data, setData] = useState({
        variantReport: [],
        drugData: [],
        traits: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('genome');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('Whole Genome');
    const [isHidden, setIsHidden] = useState(false);
    const [activeDiseaseIndex, setActiveDiseaseIndex] = useState(null);
    const [selectedDisease, setSelectedDisease] = useState(null);
    const [isGenomicModalVisible, setGenomicModalVisible] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [geneList, setGeneList] = useState([]);
    const [geneSearchTerm, setGeneSearchTerm] = useState('');
    const [selectedGenes, setSelectedGenes] = useState([]);
    const [temporaryFilterActive, setTemporaryFilterActive] = useState(false);
    const [temporarySelectedGenes, setTemporarySelectedGenes] = useState([]);
    const [isFilterLocked, setIsFilterLocked] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);
    const [newTemplateName, setNewTemplateName] = useState("New Template");
    const [isNewTemplateAdded, setIsNewTemplateAdded] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [dynamicButtonIcon, setDynamicButtonIcon] = useState(faFileCsv);
    const [hiddenGenes, setHiddenGenes] = useState([]);
    const [switchStates, setSwitchStates] = useState({});
    const [showRegenerationModal, setShowRegenerationModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationEmail, setNotificationEmail] = useState('');
    const [notificationVisible, setNotificationVisible] = useState(false);
    const fileInputRef = useRef(null);
    const templateScrollRef = useRef(null);

    const handleClearFilters = () => {
        setTemporarySelectedGenes([]);
        setTemporaryFilterActive(false);
        setSelectedGenes([]);
        setIsFilterLocked(false);
        setPopupVisible(false);

    };

    const handleApplyGenes = () => {
        if (temporarySelectedGenes.length === 0) {
            handleClearFilters();
            return;
        }
        setSelectedGenes(temporarySelectedGenes);
        setTemporaryFilterActive(temporarySelectedGenes.length > 0);
        setPopupVisible(false);
        if (temporarySelectedGenes.length > 0) {
            setSelectedFilter('Whole Genome');
            setIsFilterLocked(true);
        }
    };

    const memoizedRenderGeneList = useMemo(() => {
        const allGenes = data.variantReport
            .flatMap(disease => disease.Genes ? [disease.Genes] : [])
            .filter((gene, index, self) => gene && self.indexOf(gene) === index);

        const sortedGenes = allGenes.sort((a, b) => {
            const aSelected = temporarySelectedGenes.includes(a);
            const bSelected = temporarySelectedGenes.includes(b);
            if (aSelected && !bSelected) return -1;
            if (!aSelected && bSelected) return 1;
            return 0;
        });

        const filteredGenes = sortedGenes.filter(gene =>
            gene.toLowerCase().includes(geneSearchTerm.toLowerCase())
        );

        return (
            <>
                {filteredGenes.map((gene, index) => (
                    <span
                        key={index}
                        className={`gene-item ${temporarySelectedGenes.includes(gene) ? 'selected' : ''}`}
                        onClick={() => {
                            if (temporarySelectedGenes.includes(gene)) {
                                setTemporarySelectedGenes(prev => prev.filter(g => g !== gene));
                            } else {
                                setTemporarySelectedGenes(prev => [...prev, gene]);
                            }
                        }}
                    >
                        {temporarySelectedGenes.includes(gene) ? (
                            <FontAwesomeIcon icon={faCheck} className="gene-icon" />
                        ) : (
                            <span className="gene-icon">+</span>
                        )}
                        {gene}
                    </span>
                ))}
            </>
        );
    }, [data.variantReport, geneSearchTerm, temporarySelectedGenes]);

    const toggleGenomicModal = () => {
        if (isGenomicModalVisible) {
            setSelectedDisease(null);
        }
        setGenomicModalVisible(!isGenomicModalVisible);
    };

    const togglePopup = () => {
        setPopupVisible(!popupVisible);

    };

    function chunkArray(array, size) {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    }

    function reorderEntries(disease) {
        const priorityKeys = ['selCSQ', 'DiseaseInfo', 'ClinVar_CLNDISDB', 'INFO'];
        const excludeKeys = ['index', 'REPORT_TAG'];
        const filteredEntries = Object.entries(disease).filter(([key]) => !excludeKeys.includes(key));
        const reorderedEntries = filteredEntries.filter(([key]) => !priorityKeys.includes(key));
        const priorityEntries = filteredEntries.filter(([key]) => priorityKeys.includes(key));
        return [...reorderedEntries, ...priorityEntries];
    }

    const searchData = () => {
        let filteredData = data.variantReport || [];

        if (temporaryFilterActive) {
            filteredData = filterDiseasesByGenes(filteredData);
        }

        if (isFilterLocked) {
            filteredData = filterDiseasesByGenes(filteredData);
        }

        if (!isFilterLocked && !temporaryFilterActive) {
            switch (selectedFilter) {
                case 'Health Risks':
                    filteredData = filteredData.filter(d => d.final_target_group?.includes('Health'));
                    break;
                case 'Newborn':
                    filteredData = filteredData.filter(d => d.final_target_group?.includes('Newborn'));
                    break;
                case 'Carrier':
                    filteredData = filteredData.filter(d => d.final_target_group?.includes('Newborn') || d.final_target_group?.includes('Carrier'));
                    break;
                case 'Cancer':
                    filteredData = filteredData.filter(d => d.final_target_group?.includes('cancer'));
                    break;
            }
        }

        return filteredData.filter(d => d.Disease?.toLowerCase().includes(searchTerm.toLowerCase()));
    };

    const filterDiseasesByGenes = (diseases) => {
        if (selectedGenes.length === 0) return diseases;

        return diseases.filter(disease => {
            return disease.Genes?.split(',').some(diseaseGene => selectedGenes.includes(diseaseGene.trim()));
        });
    };

    const renderTable = () => {
        return (
            <div className="gr-table-container">

                {activeTab === 'genome' && renderTableContent(searchData(), 'variants')}
                {activeTab === 'drugs' && renderTableContent(data.drugData, 'drugs')}

                {activeTab === 'traits' && renderTableContent(data.traits, 'traits')}
            </div>
        );
    };

    const renderTableContent = (data, type) => {
        if (!data || data.length === 0) {
            return <p className="gr-empty">No data available</p>;
        }

        return (
            <div className="gr-genomics-content">
                <div className="gr-genomics-list">
                    {data.map((item, index) => (
                        <div key={index} className={`gr-genomics-item ${activeDiseaseIndex !== index ? 'closed' : ''}`}>
                            <div className={`gr-disease-row ${type === 'variants' && switchStates[item.Disease] === false ? 'gr-disease-row-hidden' : ''}`} onClick={() => setActiveDiseaseIndex(prevIndex => prevIndex === index ? null : index)}>
                                {type === 'drugs' ? (
                                    <>
                                        <div className="gr-cell-value">
                                            <span>{(item['Drug.s.'] || 'Unknown Drug').charAt(0).toUpperCase() + (item['Drug.s.'] || 'Unknown Drug').slice(1).toLowerCase()}</span>
                                        </div>
                                        <div className="gr-disease-info">
                                            <div className="gr-disease-impact">Score: {item.Score}</div>
                                        </div>
                                    </>
                                ) : type === 'traits' ? (
                                    <>
                                        <div className="gr-cell-value">
                                            <span>{item['category'] || 'Unknown Trait'}</span>
                                        </div>
                                        <div className="gr-disease-info">
                                            <div className="gr-disease-impact">{item.Zygosity}</div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="gr-cell-value">
                                            <FontAwesomeIcon
                                                icon={switchStates[item.Disease] === false ? faEyeLowVision : faEye}
                                                className="gr-eye-icon"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            />
                                            <span>{item.Disease || 'Unknown Disease'}</span>
                                        </div>
                                        <div className="gr-disease-info">
                                            <div className="gr-disease-status">
                                                {item.ClinVar_CLNSIG && item.ClinVar_CLNSIG.includes('Conflicting_interpretations_of_pathogenicity')
                                                    ? 'Unclear Pathogenicity'
                                                    : !item.ClinVar_CLNSIG
                                                        ? 'Novel Variant'
                                                        : item.ClinVar_CLNSIG}
                                            </div>
                                            <div className="gr-disease-impact">{item.IMPACT}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={`gr-card ${activeDiseaseIndex === index ? 'open' : ''}`}>
                                {type === 'drugs' ? (
                                    <>
                                        <div className="gr-full-width">
                                            <div className="gr-cell-title">Genotype:</div>
                                            <div className="gr-cell">{item.Genotype}</div>
                                        </div>
                                        <div className="gr-grid">
                                            <div>
                                                <div className="gr-cell-title">Zygosity:</div>
                                                <div className="gr-cell">{item.Zygosity}</div>
                                            </div>
                                            <div>
                                                <div className="gr-cell-title">Phenotype:</div>
                                                <div className="gr-cell">{item['Phenotype.s.']}</div>
                                            </div>
                                            <div>
                                                <div className="gr-cell-title">Gene:</div>
                                                <div className="gr-cell">{item.Gene}</div>
                                            </div>
                                            <div>
                                                <div className="gr-cell-title">Level of Evidence:</div>
                                                <div className="gr-cell">{item['Level.of.Evidence']}</div>
                                            </div>
                                        </div>
                                        <div className="gr-full-width">
                                            <div className="gr-cell-title">Annotation Text:</div>
                                            <div className="gr-cell">{item['Annotation.Text']}</div>
                                        </div>
                                    </>
                                ) : type === 'traits' ? (
                                    <>
                                        <div className="gr-full-width">
                                            <div className="gr-cell-title">Description:</div>
                                            <div className="gr-cell">{item.Description}</div>
                                        </div>
                                        <div className="gr-grid">
                                            <div>
                                                <div className="gr-cell-title">Patient Genotype:</div>
                                                <div className="gr-cell">{item['Patient_genotypes']}</div>
                                            </div>
                                            <div>
                                                <div className="gr-cell-title">Genes:</div>
                                                <div className="gr-cell">{item.genes}</div>
                                            </div>
                                            <div>
                                                <div className="gr-cell-title">Variants:</div>
                                                <div className="gr-cell">{item.variants}</div>
                                            </div>
                                            <div>
                                                <div className="gr-cell-title">Zygosity:</div>
                                                <div className="gr-cell">{item.Zygosity}</div>
                                            </div>
                                        </div>
                                        <div className="gr-full-width">
                                            <div className="gr-cell-title">Genotype Description:</div>
                                            <div className="gr-cell">{item['Genotype_Description']}</div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="gr-full-width">
                                            <div className="gr-cell-title">Genomic Position:</div>
                                            <div className="gr-cell">{item.ClinVar_CLNHGVS}</div>
                                        </div>
                                        <div className="gr-grid">
                                            <div>
                                                <div className="gr-cell-title">Genes:</div>
                                                <div className="gr-cell">{item.Genes}</div>
                                            </div>
                                            <div>
                                                <div className="gr-cell-title">Pathogenicity:</div>
                                                <div className="gr-cell">{item.ClinVar_CLNSIG}</div>
                                            </div>
                                            <div>
                                                <div className="gr-cell-title">Zygosity:</div>
                                                <div className="gr-cell">{item.Zygosity}</div>
                                            </div>
                                            <div>
                                                <div className="gr-cell-title">Inheritances:</div>
                                                <div className="gr-cell">{item.Inheritances}</div>
                                            </div>
                                        </div>
                                        <div className="gr-full-width">
                                            <div className="gr-cell-title">Disease Information:</div>
                                            <div className="gr-cell">{item.DiseaseInfo}</div>
                                        </div>
                                    </>
                                )}
                                <div className="gr-card-footer">
                                    {type === 'drugs' && (
                                        <div className="gr-additional-info">
                                            <a href={item.URL} target="_blank" rel="noopener noreferrer" className="gr-info-button">
                                                <FontAwesomeIcon icon={faCirclePlus} />
                                                <span>View Source</span>
                                            </a>
                                        </div>
                                    )}
                                    {type === 'variants' && (
                                        <label className="gr-switch">
                                            <input
                                                type="checkbox"
                                                checked={switchStates[item.Disease] !== false}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setSwitchStates(prev => ({
                                                        ...prev,
                                                        [item.Disease]: isChecked
                                                    }));
                                                    if (!isChecked) {
                                                        setHiddenGenes(prev => [...prev, {
                                                            disease: item.Disease,
                                                            genes: item.Genes,
                                                            variants: item.ClinVar_CLNHGVS
                                                        }]);
                                                    } else {
                                                        setHiddenGenes(prev =>
                                                            prev.filter(gene => gene.disease !== item.Disease)
                                                        );
                                                    }
                                                }}
                                            />
                                            <span className="gr-slider"></span>
                                        </label>
                                    )}
                                    <div className="gr-additional-info" onClick={() => {
                                        setSelectedDisease(item);
                                        toggleGenomicModal();
                                    }}>
                                        <div className="gr-info-button">
                                            <FontAwesomeIcon icon={faCirclePlus} />
                                            <span>Additional Information</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    useEffect(() => {
        const fetchGenomicData = async () => {
            try {
                setIsLoading(true);
                setError(null);
                const response = await fetch(`https://service8.szapfs.org/api/generiskpro/genomic-data/${reportId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch genomic data');
                }
                const jsonData = await response.json();
                setData(jsonData);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (reportId) {
            fetchGenomicData();
        }
    }, [reportId]);

    useEffect(() => {
        if (showNotification) {
            setNotificationVisible(true);
            const timer = setTimeout(() => {
                setNotificationVisible(false);
                setTimeout(() => {
                    setShowNotification(false);
                }, 300); // Wait for fade out
            }, 5000); // Changed from 3000 to 5000 for 5 seconds
            return () => clearTimeout(timer);
        }
    }, [showNotification]);

    const exportAllData = () => {
        const workbook = XLSX.utils.book_new();
        
        // Export Traits
        const traitsWS = XLSX.utils.json_to_sheet(data.traits);
        XLSX.utils.book_append_sheet(workbook, traitsWS, "Traits");
        
        // Export Drugs
        const drugsWS = XLSX.utils.json_to_sheet(data.drugData);
        XLSX.utils.book_append_sheet(workbook, drugsWS, "Drugs");
        
        // Export Genome
        const genomeWS = XLSX.utils.json_to_sheet(data.variantReport);
        XLSX.utils.book_append_sheet(workbook, genomeWS, "Genome");
        
        // Save the file
        XLSX.writeFile(workbook, "genomic_data_export.xlsx");
    };

    if (isLoading) {
        return (
            <div className="gr-loading">
                <FontAwesomeIcon icon={faSpinner} spin />
                <p>Loading genomic data...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="gr-error">
                <p>Error loading genomic data: {error}</p>
            </div>
        );
    }

    return (
        <div className="gr-genomic-data">
            <div className="gr-genomic-data-header">
                Genomic Data Analysis
                <button onClick={exportAllData} className="export-button">
                    <FontAwesomeIcon icon={faFileCsv} /> Export Data
                </button>

                <div className="gr-tabs">
                    <button
                        className={`gr-tab ${activeTab === 'genome' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab('genome');
                        }}
                    >
                        Genome
                    </button>
                    <button
                        className={`gr-tab ${activeTab === 'traits' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab('traits');
                            setSelectedGenes([]);
                            setTemporarySelectedGenes([]);
                            setTemporaryFilterActive(false);
                            setIsFilterLocked(false);
                            setSelectedFilter('Whole Genome');
                        }}
                    >
                        Traits
                    </button>
                    <button
                        className={`gr-tab ${activeTab === 'drugs' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab('drugs');
                            setSelectedGenes([]);
                            setTemporarySelectedGenes([]);
                            setTemporaryFilterActive(false);
                            setIsFilterLocked(false);
                            setSelectedFilter('Whole Genome');
                        }}
                    >
                        Drugs
                    </button>

                </div>
            </div>

            <div className="gr-search-controls">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="gr-search-bar"
                />
                <div className='gr-filter-categories'>
                    {activeTab === 'genome' && ['Whole Genome', 'Health Risks', 'Newborn', 'Carrier', 'Cancer'].map((filter) => (
                        <button
                            key={filter}
                            onClick={() => {
                                if (!isFilterLocked) {
                                    setSelectedFilter(filter);
                                    setSelectedGenes([]);
                                    setTemporarySelectedGenes([]);
                                    setTemporaryFilterActive(false);
                                    setIsFilterLocked(false);
                                }
                            }}
                            className={`gr-filter-button ${selectedFilter === filter ? 'gr-filter-button-selected' : ''} ${isFilterLocked ? 'gr-filter-button-disabled' : ''}`}
                            disabled={isFilterLocked}
                        >
                            {filter}
                        </button>
                    ))}
                    {activeTab === 'genome' && (
                        <div className="gr-gene-filter">
                            <FontAwesomeIcon
                                icon={faFilter}
                                onClick={togglePopup}
                                className={temporaryFilterActive || isFilterLocked ? 'gr-blue-filter-icon' : ''}
                            />
                        </div>
                    )}
                </div>
            </div>

            {popupVisible && (
                <div className="genomics-popup">
                    <div className="genomics-popup__header">
                        <input
                            type="text"
                            placeholder="Search or Add Gene..."
                            className="genomics-popup__search-bar"
                            value={geneSearchTerm}
                            onChange={(e) => setGeneSearchTerm(e.target.value.toUpperCase())}
                        />
                        <button className="genomics-popup__close-btn" onClick={togglePopup}>X</button>
                    </div>
                    <div className="genomics-popup__body">
                        {memoizedRenderGeneList}
                    </div>
                    <div className="genomics-popup__footer">
                        <button
                            className="clear-button"
                            onClick={handleClearFilters}
                        >
                            Clear Filter
                        </button>
                        <button
                            className="apply-button"
                            onClick={handleApplyGenes}
                        >
                            Apply
                        </button>

                    </div>
                </div>
            )}

                <div className="gr-genomic-data-content">
                    {renderTable()}
                </div>

            {isGenomicModalVisible && selectedDisease && (
                <div className="gr-modal-backdrop" onClick={toggleGenomicModal}>
                    <div className="gr-modal-content" onClick={e => e.stopPropagation}>
                        <div className="gr-modal-header">
                            <span>{selectedDisease.Disease}</span>
                            <button className="gr-modal-close" onClick={toggleGenomicModal}>×</button>
                        </div>
                        <div className="gr-modal-body">
                            {chunkArray(reorderEntries(selectedDisease), 4).map((chunk, index) => (
                                <div className="gr-modal-grid" key={index}>
                                    {chunk.map(([key, value], idx) => (
                                        <div key={idx} className="gr-modal-cell">
                                            <div className="gr-modal-cell-title">{key}:</div>
                                            <div className="gr-modal-cell-value">{value !== null ? value.toString() : "N/A"}</div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <div className="gr-hidden-genes-modal">
            <div className="gr-hidden-genes-header">Hidden Genes/Variants</div>

                <div className="gr-hidden-genes-content">
                    {hiddenGenes.length > 0 ? (
                        hiddenGenes.map((item, index) => (
                            <div key={index} className="gr-hidden-gene-item">
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="gr-unhide-icon"
                                    onClick={() => {
                                        setSwitchStates(prev => ({
                                            ...prev,
                                            [item.disease]: true
                                        }));
                                        setHiddenGenes(prev => 
                                            prev.filter(gene => gene.disease !== item.disease)
                                        );
                                    }}
                                />
                                <div className="gr-hidden-gene-item-content">
                                    <div><strong>Disease:</strong> {item.disease}</div>
                                    <div><strong>Genes:</strong> {item.genes}</div>
                                    <div><strong>Variants:</strong> {item.variants}</div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="gr-no-genes-message">
                            No hidden genes/variants
                        </div>
                    )}
                </div>
                <button 
                    className="gr-run-button" 
                    onClick={() => setShowRegenerationModal(true)}
                    disabled={hiddenGenes.length === 0}
                >
                    ({hiddenGenes.length}) Apply & Regenerate
                </button>
            </div>

            {showRegenerationModal && (
                <GenomicRegeneration
                    reportId={reportId}
                    user={user}
                    hiddenGenes={hiddenGenes}
                    onClose={() => setShowRegenerationModal(false)}
                    onSubmit={(email) => {
                        setNotificationEmail(email);
                        setShowNotification(true);
                    }}
                />
            )}

            {showNotification && (
                <div className={`gen-reg-notification ${notificationVisible ? 'visible' : ''}`}>
                    <FontAwesomeIcon icon={faCheck} className="gen-reg-check-icon" />
                    <span>Thank you! Sending filtered report to {notificationEmail} within 5 minutes</span>
                </div>
            )}
        </div>
    );
};

export default GRGenomicData;

import React, { useState, useEffect, useMemo } from 'react';
import './UploadModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimes,
    faCloudArrowUp,
    faSearch,
    faCheck,
    faChevronDown,
    faChevronUp
} from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

const CHUNK_SIZE = 80 * 1024 * 1024; // 80MB

function UploadModal({ isOpen, onClose }) {
    const [parentProjects, setParentProjects] = useState([]);
    const [selectedParentId, setSelectedParentId] = useState(null);
    const [expandedParentId, setExpandedParentId] = useState(null);
    const [subprojects, setSubprojects] = useState({});
    const [selectedSubprojectId, setSelectedSubprojectId] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedAnalysis, setSelectedAnalysis] = useState('');
    const [selectedSample, setSelectedSample] = useState('');
    const [selectedFiles, setSelectedFiles] = useState({ read1: null, read2: null });
    const [patients, setPatients] = useState([]);
    const [patientSearch, setPatientSearch] = useState('');
    const [analysisTypes, setAnalysisTypes] = useState({});
    const [sampleTypes, setSampleTypes] = useState({});
    const [supportedSamples, setSupportedSamples] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({ read1: 0, read2: 0 });
    const [isUploading, setIsUploading] = useState(false);
    const [currentFileName, setCurrentFileName] = useState('');
    const [newFileName, setNewFileName] = useState('');
    const [pool79, setPool79] = useState(null);
    const [pool80, setPool80] = useState(null);

    const resetSelections = () => {
        setSelectedParentId(null);
        setExpandedParentId(null);
        setSelectedSubprojectId(null);
        setSelectedPatient(null);
        setSelectedAnalysis('');
        setSelectedSample('');
        setSelectedFiles({ read1: null, read2: null });
        setPatientSearch('');
    };

    useEffect(() => {
        if (isOpen) {
            const fetchParentProjects = async () => {
                try {
                    const response = await fetch('https://service9.szapfs.org/parent-projects');
                    if (!response.ok) throw new Error('Network response was not ok');
                    const data = await response.json();
                    setParentProjects(data.sort((a, b) => a.id - b.id));
                } catch (error) {
                    console.error('Error fetching parent projects:', error);
                }
            };
            fetchParentProjects();
        } else {
            resetSelections();
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            const fetchAnalysisTypes = async () => {
                try {
                    console.log('Fetching supported analyses...');
                    const response = await fetch('https://service9.szapfs.org/supported-analyses');
                    if (!response.ok) throw new Error('Network response was not ok');
                    const data = await response.json();
                    console.log('Received analyses:', data);
                    setAnalysisTypes(data.analyses);
                    setSampleTypes(data.sampleTypes);
                } catch (error) {
                    console.error('Error fetching analysis types:', error);
                }
            };
            fetchAnalysisTypes();
        }
    }, [isOpen]);

    useEffect(() => {
        if (expandedParentId) {
            const fetchSubprojects = async () => {
                try {
                    console.log('Fetching subprojects for parent:', expandedParentId);
                    const response = await fetch(`https://service9.szapfs.org/parent-project/${expandedParentId}/subprojects`);
                    console.log('Response:', response);
                    if (!response.ok) throw new Error('Network response was not ok');
                    const data = await response.json();
                    console.log('Subprojects data:', data);
                    setSubprojects(prev => ({
                        ...prev,
                        [expandedParentId]: data
                    }));
                } catch (error) {
                    console.error('Error fetching subprojects:', error);
                }
            };
            fetchSubprojects();
        }
    }, [expandedParentId]);

    useEffect(() => {
        if (selectedParentId && selectedSubprojectId) {
            const fetchPatients = async () => {
                try {
                    console.log('Fetching patients:', selectedParentId, selectedSubprojectId);
                    const response = await fetch(`https://service9.szapfs.org/project/${selectedParentId}/${selectedSubprojectId}/patients`);
                    console.log('Patients response:', response);
                    if (!response.ok) throw new Error('Network response was not ok');
                    const data = await response.json();
                    console.log('Patients data:', data);
                    const sortedData = data.sort((a, b) => {
                        const aNum = parseInt(a.patient_id.split('_')[2]) || 0;
                        const bNum = parseInt(b.patient_id.split('_')[2]) || 0;
                        return aNum - bNum;
                    });
                    setPatients(sortedData);
                } catch (error) {
                    console.error('Error fetching patients:', error);
                }
            };
            fetchPatients();
        } else {
            setPatients([]);
        }
        setSelectedPatient(null);
        setSelectedAnalysis('');
        setSelectedSample('');
        setSelectedFiles({ read1: null, read2: null });
    }, [selectedParentId, selectedSubprojectId]);

    useEffect(() => {
        if (selectedAnalysis && analysisTypes[selectedAnalysis]) {
            setSupportedSamples(analysisTypes[selectedAnalysis].supportedSamples);
            setSelectedSample('');
        } else {
            setSupportedSamples([]);
        }
    }, [selectedAnalysis, analysisTypes]);

    const handleParentClick = (parentId) => {
        setSelectedParentId(parentId);
        setExpandedParentId(expandedParentId === parentId ? null : parentId);
        if (expandedParentId !== parentId) {
            setSelectedSubprojectId(null);
        }
    };

    const handleSubprojectClick = (subprojectId) => {
        setSelectedSubprojectId(subprojectId);
    };

    const handleFileSelect = (event, fileType) => {
        const file = event.target.files[0];
        setSelectedFiles(prev => ({
            ...prev,
            [fileType]: file
        }));
    };

    const uploadChunk = async (file, chunkIndex, uploadId, metadata, readType) => {
        const start = chunkIndex * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
    
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('chunkIndex', chunkIndex);
        formData.append('totalChunks', Math.ceil(file.size / CHUNK_SIZE));
        formData.append('fileName', `${file.name}`);
        formData.append('metadata', JSON.stringify({ ...metadata, readType }));
        
        if (uploadId) {
            formData.append('uploadId', uploadId);
        }
    
        const response = await fetch('https://service9.szapfs.org/upload-chunk', {
            method: 'POST',
            body: formData
        });
    
        if (!response.ok) throw new Error('Chunk upload failed');
        return response.json();
    };
    
    const handleUpload = async () => {
        const isMetagenomics = selectedAnalysis === 'MG';
        if (isMetagenomics && (!selectedFiles.read1 || !selectedFiles.read2)) return;
        if (!isMetagenomics && !selectedFiles.read1) return;

        setIsUploading(true);
        
        try {
            const metadata = {
                patientId: selectedPatient.patient_id,
                analysisCode: selectedAnalysis,
                sampleCode: selectedSample,
                parentId: selectedParentId,
                subprojectId: selectedSubprojectId
            };

            // Upload Read 1
            const file1 = selectedFiles.read1;
            setCurrentFileName(file1.name);
            const totalChunks1 = Math.ceil(file1.size / CHUNK_SIZE);
            let uploadId1 = null;
            let lastResult1 = null;

            for (let i = 0; i < totalChunks1; i++) {
                const result = await uploadChunk(file1, i, uploadId1, metadata, 'R1');
                lastResult1 = result;
                if (i === 0) uploadId1 = result.uploadId;
                setUploadProgress(prev => ({ ...prev, read1: ((i + 1) / totalChunks1) * 100 }));
            }

            // Upload Read 2 if metagenomics
            if (isMetagenomics && selectedFiles.read2) {
                const file2 = selectedFiles.read2;
                setCurrentFileName(file2.name);
                const totalChunks2 = Math.ceil(file2.size / CHUNK_SIZE);
                let uploadId2 = null;
                let lastResult2 = null;

                for (let i = 0; i < totalChunks2; i++) {
                    const result = await uploadChunk(file2, i, uploadId2, metadata, 'R2');
                    lastResult2 = result;
                    if (i === 0) uploadId2 = result.uploadId;
                    setUploadProgress(prev => ({ ...prev, read2: ((i + 1) / totalChunks2) * 100 }));
                }
            }

            // Reset and close after successful upload
            setTimeout(() => {
                setIsUploading(false);
                setUploadProgress({ read1: 0, read2: 0 });
                setCurrentFileName('');
                setNewFileName('');
                setSelectedFiles({ read1: null, read2: null });
                resetSelections();
                onClose();
            }, 2000);

        } catch (error) {
            console.error('Upload error:', error);
            setIsUploading(false);
            setUploadProgress({ read1: 0, read2: 0 });
            setCurrentFileName('');
            setNewFileName('');
        }
    };

    const filteredPatients = useMemo(() => {
        if (!patientSearch) return patients;
        const searchLower = patientSearch.toLowerCase();
        return patients.filter(patient => 
            (patient.name?.toLowerCase().includes(searchLower) || 
             patient.patient_id?.toLowerCase().includes(searchLower))
        );
    }, [patients, patientSearch]);

    if (!isOpen) return null;

    return (
        <div className="UPM-modal-overlay">
            <div className="UPM-modal">
                <div className="UPM-modal-header">
                    <h2>Upload Data</h2>
                    {!isUploading && (
                        <button className="UPM-close-button" onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    )}
                </div>
                <div className="UPM-modal-content">
                    {isUploading ? (
                        <div className="UPM-upload-progress">
                            <h3>Uploading {currentFileName}</h3>
                            {newFileName && <p>New file: {newFileName}</p>}
                            {selectedAnalysis === 'MG' ? (
                                <div>
                                    <div className="progress-container">
                                        <label>Read 1:</label>
                                        <div className="progress-bar">
                                            <div 
                                                className="progress" 
                                                style={{
                                                    width: `${uploadProgress.read1}%`,
                                                    background: 'linear-gradient(170deg, #2595be7e 0%, #20558aec 100%)'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="progress-container">
                                        <label>Read 2:</label>
                                        <div className="progress-bar">
                                            <div 
                                                className="progress" 
                                                style={{
                                                    width: `${uploadProgress.read2}%`,
                                                    background: 'linear-gradient(170deg, #2595be7e 0%, #20558aec 100%)'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="progress-bar">
                                    <div 
                                        className="progress" 
                                        style={{
                                            width: `${uploadProgress.read1}%`,
                                            background: 'linear-gradient(170deg, #2595be7e 0%, #20558aec 100%)'
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="UPM-selection-grid">
                            <div className="UPM-form-section">
                                <h3>Project</h3>
                                <div className="UPM-selection-box">
                                    <div className="UPM-selection-list">
                                        {parentProjects.map(parent => (
                                            <div key={parent.id}>
                                                <div 
                                                    className={`UPM-selection-item ${selectedParentId === parent.id ? 'selected' : ''}`}
                                                    onClick={() => handleParentClick(parent.id)}
                                                >
                                                    <span className="UPM-item-name">{parent.name}</span>
                                                    <FontAwesomeIcon 
                                                        icon={expandedParentId === parent.id ? faChevronUp : faChevronDown} 
                                                        className="UPM-expand-icon"
                                                    />
                                                </div>
                                                {expandedParentId === parent.id && subprojects[parent.id] && (
                                                    <div className="UPM-subprojects-list">
                                                        {subprojects[parent.id].map(sub => (
                                                            <div 
                                                                key={sub.project_id}
                                                                className={`UPM-subproject-item ${selectedSubprojectId === sub.project_id ? 'selected' : ''}`}
                                                                onClick={() => handleSubprojectClick(sub.project_id)}
                                                            >
                                                                <span className="UPM-item-name">Project {sub.project_id}</span>
                                                                <span className="UPM-item-id">{sub.project_id}</span>
                                                             
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="UPM-form-section">
                                <h3>Patient</h3>
                                <div className="UPM-selection-box">
                                    <div className="FUPM-search-box">
                                        <input
                                            type="text"
                                            placeholder="Search patients..."
                                            value={patientSearch}
                                            onChange={(e) => setPatientSearch(e.target.value)}
                                            disabled={!selectedSubprojectId}
                                        />
                                    </div>
                                    <div className="UPM-selection-list">
                                        {filteredPatients.map(patient => (
                                            <div 
                                                key={patient.patient_id} 
                                                className={`UPM-selection-item ${selectedPatient?.patient_id === patient.patient_id ? 'selected' : ''}`}
                                                onClick={() => setSelectedPatient(patient)}
                                            >
                                                <span className="UPM-item-name">{patient.name || 'No Name'}</span>
                                                <span className="UPM-item-id">{patient.patient_id}</span>
                                                {selectedPatient?.patient_id === patient.patient_id && (
                                                    <FontAwesomeIcon icon={faCheck} className="UPM-check-icon" />
                                                )}
                                            </div>
                                        ))}
                                        {selectedSubprojectId && filteredPatients.length === 0 && (
                                            <div className="UPM-no-results">No patients found</div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="UPM-form-section">
                                <h3>Analysis Type</h3>
                                <select 
                                    value={selectedAnalysis} 
                                    onChange={(e) => setSelectedAnalysis(e.target.value)}
                                    disabled={!selectedPatient}
                                >
                                    <option value="">Select Analysis Type</option>
                                    {Object.entries(analysisTypes).map(([code, details]) => (
                             <option key={code} value={code}>
                             {details.name.charAt(0).toUpperCase() + details.name.slice(1).toLowerCase()} ({code})
                         </option>
                         
                                    ))}
                                </select>
                            </div>

                            <div className="UPM-form-section">
                                <h3>Sample Type</h3>
                                <select 
                                    value={selectedSample} 
                                    onChange={(e) => setSelectedSample(e.target.value)}
                                    disabled={!selectedAnalysis || supportedSamples.length === 0}
                                >
                                    <option value="">Select Sample Type</option>
                                    {supportedSamples.map(code => (
                                        <option key={code} value={code}>
                                            {sampleTypes[code]?.name} ({code})
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="UPM-file-section">
                                <h3>Upload Files</h3>
                                {selectedAnalysis === 'MG' ? (
                                    <div className="UPM-dual-file-upload">
                                        <div className="UPM-file-input">
                                            <label className={`UPM-file-input-label ${!selectedSample ? 'disabled' : ''}`}>
                                                <input
                                                    type="file"
                                                    onChange={(e) => handleFileSelect(e, 'read1')}
                                                    accept=".fastq.gz"
                                                    disabled={!selectedSample}
                                                    style={{ display: 'none' }}
                                                />
                                                <div className="UPM-file-input-content">
                                                    <FontAwesomeIcon icon={faCloudArrowUp} className="UPM-file-icon" />
                                                    <span>Select Read 1 FASTQ</span>
                                                </div>
                                            </label>
                                            {selectedFiles.read1 && (
                                                <div className="UPM-selected-file">
                                                    Selected: {selectedFiles.read1.name}
                                                </div>
                                            )}
                                        </div>
                                        <div className="UPM-file-input">
                                            <label className={`UPM-file-input-label ${!selectedSample ? 'disabled' : ''}`}>
                                                <input
                                                    type="file"
                                                    onChange={(e) => handleFileSelect(e, 'read2')}
                                                    accept=".fastq.gz"
                                                    disabled={!selectedSample}
                                                    style={{ display: 'none' }}
                                                />
                                                <div className="UPM-file-input-content">
                                                    <FontAwesomeIcon icon={faCloudArrowUp} className="UPM-file-icon" />
                                                    <span>Select Read 2 FASTQ</span>
                                                </div>
                                            </label>
                                            {selectedFiles.read2 && (
                                                <div className="UPM-selected-file">
                                                    Selected: {selectedFiles.read2.name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="UPM-file-input">
                                        <label className={`UPM-file-input-label ${!selectedSample ? 'disabled' : ''}`}>
                                            <input
                                                type="file"
                                                onChange={(e) => handleFileSelect(e, 'read1')}
                                                disabled={!selectedSample}
                                                style={{ display: 'none' }}
                                            />
                                            <div className="UPM-file-input-content">
                                                <FontAwesomeIcon icon={faCloudArrowUp} className="UPM-file-icon" />
                                                <span>Select File to Upload</span>
                                            </div>
                                        </label>
                                        {selectedFiles.read1 && (
                                            <div className="UPM-selected-file">
                                                Selected: {selectedFiles.read1.name}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="UPM-modal-footer">
                    {!isUploading && (
                        <button 
                            className="UPM-upload-button"
                            onClick={handleUpload}
                            disabled={
                                !selectedParentId || 
                                !selectedSubprojectId || 
                                !selectedPatient || 
                                !selectedAnalysis || 
                                !selectedSample || 
                                !selectedFiles.read1 || 
                                (selectedAnalysis === 'MG' && !selectedFiles.read2)
                            }
                        >
                            <FontAwesomeIcon icon={faCloudArrowUp} /> Upload
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UploadModal;

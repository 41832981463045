import React, { useState } from 'react';
import './GenomicRegeneration.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const GenomicRegeneration = ({ reportId, user, hiddenGenes, onClose, onSubmit }) => {
    const [email, setEmail] = useState('');
    const [isFading, setIsFading] = useState(false);

    const handleClose = () => {
        setIsFading(true);
        setTimeout(() => {
            onClose();
        }, 300);
    };

    const handleSubmitReport = async () => {
        try {
            const response = await fetch('https://service8.szapfs.org/api/generiskprosubmitreport', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    reportId: reportId,
                    hiddenGenes: hiddenGenes,
                    email: email
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            
      
        } catch (error) {
            console.error('Error submitting report:', error);
        }
    };

    const handleSubmit = () => {
        setIsFading(true);
        setTimeout(() => {
            onClose();
            onSubmit(email);
        }, 300);
        handleSubmitReport();

    };

    return (
        <div className={`gen-reg-modal-backdrop ${isFading ? 'fade-out' : ''}`} onClick={handleClose}>
            <div className={`gen-reg-modal ${isFading ? 'fade-out' : ''}`} onClick={e => e.stopPropagation()}>
                <div className="gen-reg-modal-content">
                    <h3>Generate Filtered Report</h3>
                    
                    <div className="gen-reg-genes-list">
                        <h4>Hidden Genes:</h4>
                        {hiddenGenes.map((item, index) => (
                            <div key={index} className="gen-reg-gene-item">
                                <div><strong>Disease:</strong> {item.disease}</div>
                                <div><strong>Genes:</strong> {item.genes}</div>
                                <div><strong>Variants:</strong> {item.variants}</div>
                            </div>
                        ))}
                    </div>

                    <div className="gen-reg-input-group">
                        <input
                            type="email"
                            placeholder="Enter your email destination of report..."
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="gen-reg-email-input"
                        />
                    </div>

                    <button 
                        className="gen-reg-submit-button"
                        onClick={handleSubmit}
                    >
                        Generate & Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GenomicRegeneration;

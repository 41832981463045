import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ProjectDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faMobile, 
    faDna, 
    faTooth, 
    faVial, 
    faFlask, 
    faDroplet,
    faAtom,
    faCode,
    faBacteria,
    faPlus,
    faCirclePlus,
    faPhone,
    faMobileScreen,
    faMobilePhone,
    faChartGantt,

    faUserGear,
    faArrowLeft,
    faPlusCircle,
    faDigitalTachograph,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import ProjectAccountAssignment from './ProjectAccountAssignment/ProjectAccountAssignment';
import PatientMetadataEditor from './PatientMetadataEditor/PatientMetadataEditor';
import ProjectInfoModal from './ProjectInfoModal';

const getReportIcon = (reportType) => {
    switch(reportType) {
        case 'Genomics': return faDna;
        case 'Gut Health': return faBacteria;
        case 'Oral Health': return faTooth;
        case 'Metabolome Plasma': return faVial;
        case 'Metabolome Urine': return faDroplet;
        case 'Metabolome Lipids': return faFlask;
        case 'Proteomics': return faAtom;
        case 'Transcriptome': return faChartGantt;
        default: return faDna;
    }
};


function ProjectDetails({ projectId, projectData, onBackClick }) {
    const [allPatients, setAllPatients] = useState([]);
    const [patients, setPatients] = useState([]);
    const [subprojects, setSubprojects] = useState([]);
    const [selectedSubproject, setSelectedSubproject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showMetadataModal, setShowMetadataModal] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [showProjectInfoModal, setShowProjectInfoModal] = useState(false);
    const [displayedPatients, setDisplayedPatients] = useState([]);
    const [filteredPatients, setFilteredPatients] = useState([]);
    const [page, setPage] = useState(0);
    const PATIENTS_PER_PAGE = 40;
    const contentRef = useRef(null);
    const scrollTimeout = useRef(null);
    const [showAddPatientModal, setShowAddPatientModal] = useState(false);
    const [addingPatient, setAddingPatient] = useState(false);
    const [selectedSubprojectForAdd, setSelectedSubprojectForAdd] = useState(null);

    const fetchSubprojectsData = async () => {
        try {
            const response = await fetch(`https://service9.szapfs.org/parent-project/${projectId}/subprojects`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setSubprojects([{ project_id: 'all', name: 'All' }, ...data]);
            if (!selectedSubproject) {
                setSelectedSubproject({ project_id: 'all', name: 'All' });
            }
        } catch (err) {
            console.error('Error fetching subprojects:', err);
            setError('Failed to load subprojects. Please try again later.');
        }
    };

    const fetchAllPatients = async () => {
        try {
            setLoading(true);
            let data = [];
            const promises = subprojects
                .filter(sp => sp.project_id !== 'all')
                .map(sp =>
                    fetch(`https://service9.szapfs.org/project/${projectId}/${sp.project_id}/patients`)
                        .then(res => res.json())
                        .then(patients => patients.map(p => ({
                            ...p,
                            project_id: sp.project_id
                        })))
                );

            const results = await Promise.all(promises);
            data = Array.from(new Map(results.flat().map(patient => [patient.patient_id, patient])).values());

            // Fetch reports for all patients
            const reportsResponse = await fetch('https://service9.szapfs.org/available-reports', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    patientIds: data.map(p => p.patient_id)
                })
            });

            if (!reportsResponse.ok) {
                throw new Error('Failed to fetch reports');
            }

            const reportsData = await reportsResponse.json();
            const patientsWithReports = data.map(patient => ({
                ...patient,
                reports: reportsData[patient.patient_id]?.reports || []
            })).sort((a, b) => {
                const aNum = parseInt(a.patient_id.split('_')[2]) || 0;
                const bNum = parseInt(b.patient_id.split('_')[2]) || 0;
                return aNum - bNum;
            });

            setAllPatients(patientsWithReports);
            if (selectedSubproject?.project_id === 'all') {
                setPatients(patientsWithReports);
            }
        } catch (err) {
            console.error('Error fetching all patients:', err);
            setError('Failed to load patients. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const fetchPatients = async () => {
        if (!selectedSubproject) return;
        
        try {
            setLoading(true);
            if (selectedSubproject.project_id === 'all') {
                setPatients(allPatients);
                setLoading(false);
            } else {
                const response = await fetch(`https://service9.szapfs.org/project/${projectId}/${selectedSubproject.project_id}/patients`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                
                // Fetch reports for these patients
                const reportsResponse = await fetch('https://service9.szapfs.org/available-reports', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        patientIds: data.map(p => p.patient_id)
                    })
                });

                if (!reportsResponse.ok) {
                    throw new Error('Failed to fetch reports');
                }

                const reportsData = await reportsResponse.json();
                const patientsWithReports = data.map(patient => ({
                    ...patient,
                    project_id: selectedSubproject.project_id,
                    reports: reportsData[patient.patient_id]?.reports || []
                })).sort((a, b) => {
                    const aNum = parseInt(a.patient_id.split('_')[2]) || 0;
                    const bNum = parseInt(b.patient_id.split('_')[2]) || 0;
                    return aNum - bNum;
                });

                setPatients(patientsWithReports);
            }
        } catch (err) {
            console.error('Error fetching patients:', err);
            setError('Failed to load patients. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleAddPatient = async (subproject) => {
        try {
            setAddingPatient(true);
            const response = await fetch(`https://service9.szapfs.org/project/${projectId}/${subproject.project_id}/add-patient`, {
                method: 'POST'
            });

            if (!response.ok) throw new Error('Failed to add patient');

            // Refresh all patients to get updated list
            await fetchAllPatients(); // This will update allPatients with the new patient
            await fetchPatients(); // This will update the current view with the latest data
            setShowAddPatientModal(false);
            setSelectedSubprojectForAdd(null);
        } catch (error) {
            console.error('Error adding patient:', error);
            alert('Failed to add patient. Please try again.');
        } finally {
            setAddingPatient(false);
        }
    };

    const handleMetadataSave = async (updatedData) => {
        try {
            const response = await fetch('https://service9.szapfs.org/patient/metadata', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    patient_id: selectedPatient.patient_id,
                    ...updatedData
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update');
            }

            // Refresh both patient lists to show updated data
            await fetchAllPatients();
            await fetchPatients();
            
            // Then close modal
            setShowMetadataModal(false);
            setSelectedPatient(null);
        } catch (error) {
            console.error('Error updating metadata:', error);
            alert('Failed to update patient information. Please try again.');
        }
    };

    useEffect(() => {
        fetchSubprojectsData();
    }, [projectId]);

    useEffect(() => {
        if (subprojects.length > 0) {
            fetchAllPatients();
        }
    }, [projectId, subprojects]);

    useEffect(() => {
        fetchPatients();
    }, [selectedSubproject, allPatients]);

    useEffect(() => {
        setDisplayedPatients([]);
        setFilteredPatients([]);
        setSearchTerm('');
        setPage(0);
    }, [selectedSubproject]);

    useEffect(() => {
        const searchLower = searchTerm.toLowerCase();
        const filtered = searchTerm 
            ? patients.filter(patient => 
                patient.patient_id.toLowerCase().includes(searchLower) ||
                (patient.name && patient.name.toLowerCase().includes(searchLower))
            )
            : patients;
        
        setFilteredPatients(filtered);
        setDisplayedPatients(filtered.slice(0, PATIENTS_PER_PAGE));
    }, [searchTerm, patients]);

    const handleScroll = useCallback((e) => {
        if (loading) return;

        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
        }

        scrollTimeout.current = setTimeout(() => {
            const element = e.target;
            const threshold = element.clientHeight * 1.2;
            const remainingScroll = element.scrollHeight - (element.scrollTop + element.clientHeight);
            
            if (remainingScroll < threshold) {
                const currentLength = displayedPatients.length;
                const totalLength = filteredPatients.length;
                
                if (currentLength < totalLength) {
                    const nextBatch = filteredPatients.slice(
                        currentLength, 
                        currentLength + PATIENTS_PER_PAGE
                    );
                    setDisplayedPatients(prev => [...prev, ...nextBatch]);
                }
            }
            scrollTimeout.current = null;
        }, 100);
    }, [loading, displayedPatients.length, filteredPatients, PATIENTS_PER_PAGE]);

    useEffect(() => {
        const content = contentRef.current?.querySelector('.PMP-content');
        if (content) {
            content.addEventListener('scroll', handleScroll);
            return () => {
                content.removeEventListener('scroll', handleScroll);
                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }
            };
        }
    }, [handleScroll]);

    const handleAddAccount = (event, patient) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setModalPosition({
            top: rect.top,
            left: rect.right + 10
        });
        setSelectedPatient(patient);
        setShowAccountModal(true);
    };

    const handleAssignAccount = async (patient, accountId) => {
        // After successful assignment, refresh all patients to get updated omics test and assignment details
        await fetchAllPatients(); // This will update allPatients with the new patient
        await fetchPatients(); // This will update the current view with the latest data
    };

    const getInitials = (name) => {
        if (!name) return '??';
        const words = name.split(' ');
        return words.slice(0, 2).reduce((acc, word) => acc + (word ? word.charAt(0) : ''), '');
    };

    const handleProjectInfo = () => {
        setShowProjectInfoModal(true);
    };

    const handleSubprojectClick = (subproject) => {
        setSelectedSubproject(subproject);
        setDisplayedPatients([]);
        setFilteredPatients([]);
        setSearchTerm('');
        setPage(0);
    };

    if (error) {
        return (
            <div className="PMP-container">
                <div className="PMP-error">{error}</div>
            </div>
        );
    }

    return (
        <div className="PMP-container" ref={contentRef}>
            <div className="PMP-header">
                <button className="PMP-back" onClick={onBackClick}>
                    <FontAwesomeIcon icon={faArrowLeft} /> 
                </button>

                <div>
                    <input 
                        className='PMP-search' 
                        type="text" 
                        placeholder="Search patients..." 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>  
                <div className="PMP-headerright"    onClick={() => setShowAddPatientModal(true)}
                > 
                <FontAwesomeIcon icon={faPlusCircle} className='PMP-headerrighticon'  /> 

            
         

                </div>  
                {!loading && (displayedPatients.length > 0 || subprojects.filter(sp => sp.project_id !== 'all').length === 0) && (
                    <div className="PMP-headerrighti" onClick={handleProjectInfo}>
                        <div className="PMP-bri" >
                            <FontAwesomeIcon icon={faDigitalTachograph} className='PMP-headerrighticon1' /> 
                            <div className="PMP-headerrightt" >
                                Project Information 
                            </div>  
                        </div>  
                    </div>
                )}

            </div>

            <div className="PMP-subprojects">
                {subprojects.map(subproject => (
                    <div
                        key={subproject.project_id}
                        className={`PMP-subproject ${selectedSubproject?.project_id === subproject.project_id ? 'selected' : ''}`}
                        onClick={() => handleSubprojectClick(subproject)}
                    >
                        {subproject.name || subproject.project_id}
                    </div>
                ))}
            </div>

            <div className="PMP-content" 
                onScroll={() => {
                    if (showAccountModal || showMetadataModal) {
                        const modal = document.querySelector('.PMPAS-modal');
                        if (modal) {
                            modal.classList.add('PMPFadeaway');
                            setTimeout(() => {
                                setShowAccountModal(false);
                                setShowMetadataModal(false);
                            }, 150);
                        }
                    }
                }}
            >              
                <div className={`PMP-grid ${loading ? 'loading' : ''}`}>
                    {loading && displayedPatients.length === 0 ? (
                        <div className="PMP-loading">Loading...</div>
                    ) : (
                        displayedPatients.map((patient) => (
                            <div key={patient.patient_id} className="PMP-patient-tile">
                                <div className="PMP-patient-header">
                                    <div className="PMP-patient-initial">
                                        {getInitials(patient?.name)}

                                        
                                        <div className="PMP-edit-icon"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedPatient(patient);
                                                setShowMetadataModal(true);
                                            }}
                                        >
                                            <FontAwesomeIcon 
                                                icon={faUserGear}
                                            />
                                        </div>
                                    </div>
                                    <div className="PMP-patient-info">
                                        <div className="PMP-patient-name">
                                            <span>{patient?.name || ''}</span>
                                            <FontAwesomeIcon 
                                                icon={patient?.Account_ID ? faMobilePhone : faCirclePlus}
                                                style={{ 
                                                    color: patient?.Account_ID ? '#20558aec' : 'lightgrey',
                                                    cursor: 'pointer'
                                                }} 
                                                onClick={(e) => handleAddAccount(e, patient)}
                                            />
                                        </div>
                                        <div className="PMP-patient-details">
                                            <span>{patient?.date_of_birth || ''}</span>
                                            <span> - {patient?.gender || ''} - </span>
                                            <span>{patient?.patient_id || ''}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="PMP-report-slots">
                                            {(patient?.reports || []).map((report, index) => (
                                                <div 
                                                    key={index} 
                                                    className="PMP-report-slot"
                                                    title={report?.type || ''}
                                                >
                                                    <div className="PMP-report-tables-tooltip">
                                                        <div className="PMP-report-tables-content">
                                                            <div className="PMP-report-tables-title">{report?.type || ''}</div>
                                                            {report?.tables?.length > 0 ? (
                                                                report.tables.map((table, i) => (
                                                                    <div key={i} className="PMP-report-table-name">
                                                                        {table || ''}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="PMP-report-table-name"></div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <FontAwesomeIcon 
                                                        icon={getReportIcon(report?.type)} 
                                                        className="PMP-report-icon" 
                                                    />
                                                    <span className="PMP-report-label">
                                                        {report?.type ? (
                                                            report.type.startsWith('Metabolome') ? 
                                                                report.type.split(' ')[1] : 
                                                                report.type.split(' ')[0]
                                                        ) : ''}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                            </div>
                        ))
                    )}
                </div>
                {loading && displayedPatients.length > 0 && (
                    <div className="PMP-loading-more">Loading more patients...</div>
                )}
                {!loading && displayedPatients.length < filteredPatients.length && (
                    <div className="PMP-scroll-info">Scroll down to load more patients</div>
                )}
            </div>
            {showAccountModal && (
                <ProjectAccountAssignment 
                    position={modalPosition}
                    onClose={() => setShowAccountModal(false)}
                    patient={selectedPatient}
                    onAssign={handleAssignAccount}
                />
            )}
            {showMetadataModal && selectedPatient && (
                <PatientMetadataEditor 
                    patient={selectedPatient}
                    onClose={() => {
                        setShowMetadataModal(false);
                        setSelectedPatient(null);
                    }}
                    onSave={handleMetadataSave}
                />
            )}
            {showProjectInfoModal && (
                <ProjectInfoModal 
                    isOpen={showProjectInfoModal} 
                    onClose={() => setShowProjectInfoModal(false)}
                    project={projectData || { id: projectId }}
                    patients={allPatients}
                    subprojects={subprojects}
                    reports={allPatients.reduce((acc, patient) => {
                        acc[patient.patient_id] = {
                            reports: patient.reports || []
                        };
                        return acc;
                    }, {})}
                    onSubprojectsUpdate={async () => {
                        await fetchSubprojectsData();
                        await fetchAllPatients();
                    }}
                />
            )}
            {showAddPatientModal && (
                <div className="PMP-modal">
                    <div className="PMP-modal-content PMP-add-patient-modal">
                        <div className="PMP-modal-header">
                            <h3>Select Subproject</h3>
                            <button 
                                className="PMP-modal-close"
                                onClick={() => {
                                    setShowAddPatientModal(false);
                                    setSelectedSubprojectForAdd(null);
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <div className="PMP-subprojects-list">
                            {subprojects
                                .filter(sp => sp.project_id !== 'all')
                                .map(subproject => (
                                    <div
                                        key={subproject.project_id}
                                        className={`PMP-subproject-option ${
                                            selectedSubprojectForAdd?.project_id === subproject.project_id ? 'selected' : ''
                                        }`}
                                        onClick={() => setSelectedSubprojectForAdd(subproject)}
                                    >
                                        {subproject.name || subproject.project_id}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="PMP-modal-footer">
                            <button
                                className="PMP-button"
                                disabled={!selectedSubprojectForAdd || addingPatient}
                                onClick={() => handleAddPatient(selectedSubprojectForAdd)}
                            >
                                {addingPatient ? 'Adding...' : 'Add Patient'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProjectDetails;
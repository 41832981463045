import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimes, 
    faProjectDiagram,
    faUsers,
    faDna,
    faTooth,
    faVial,
    faFlask,
    faDroplet,
    faAtom,
    faCode,
    faBacteria,
    faPlus,
    faChartBar,
    faSearch,
    faInfoCircle,
    faFolder,
    faFolderOpen
} from '@fortawesome/free-solid-svg-icons';
import './ProjectInfoModal.css';

const ProjectInfoModal = ({ isOpen, onClose, project, subprojects, patients, reports, onSubprojectsUpdate }) => {
    console.log('ProjectInfoModal received project:', project);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddSubproject, setShowAddSubproject] = useState(false);
    const [newSubproject, setNewSubproject] = useState({
        id: '',
        numPatients: ''
    });
    const [isCreating, setIsCreating] = useState(false);

    const REPORT_TYPES = {
        'WGS': { icon: faDna },
        'Gut Health': { icon: faBacteria },
        'Oral Health': { icon: faTooth },
        'Metabolome Plasma': { icon: faVial },
        'Metabolome Urine': { icon: faDroplet },
        'Metabolome Lipids': { icon: faFlask },
        'Proteomics': { icon: faAtom },
        'Transcriptome': { icon: faCode }
    };

    if (!isOpen) return null;

    const getReportStats = (patientIds) => {
        const stats = {};
        Object.keys(REPORT_TYPES).forEach(type => {
            stats[type] = {
                count: 0,
                total: patientIds.length
            };
        });

        patientIds.forEach(patientId => {
            const patientReports = reports[patientId]?.reports || [];
            patientReports.forEach(report => {
                if (stats[report.type]) {
                    stats[report.type].count++;
                }
            });
        });

        return stats;
    };

    const getTotalReportCount = (stats) => {
        return Object.values(stats).reduce((total, stat) => total + stat.count, 0);
    };

    const getSubprojectStats = (subproject) => {
        const subprojectPatients = patients.filter(
            p => p.project_id === subproject.project_id
        );
        const patientIds = subprojectPatients.map(p => p.patient_id);
        return getReportStats(patientIds);
    };

    const getTotalSubprojects = () => {
        return subprojects.filter(sp => sp.project_id !== 'all').length;
    };

    const totalStats = getReportStats(patients.map(p => p.patient_id));

    // Sort subprojects by total number of omics tests
    const filteredSubprojects = subprojects
        .filter(sp => sp.project_id !== 'all')
        .filter(sp => 
            sp.project_id.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            const aPatients = patients.filter(p => p.project_id === a.project_id);
            const bPatients = patients.filter(p => p.project_id === b.project_id);
            
            const aStats = getReportStats(aPatients.map(p => p.patient_id));
            const bStats = getReportStats(bPatients.map(p => p.patient_id));
            
            return getTotalReportCount(bStats) - getTotalReportCount(aStats);
        });

    const handleAddSubproject = async () => {
        if (!newSubproject.id || !newSubproject.numPatients) return;
        
        setIsCreating(true);
        try {
            const response = await fetch(`https://service9.szapfs.org/parent-project/${project.id}/subproject`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    subprojectId: newSubproject.id,
                    numPatients: parseInt(newSubproject.numPatients)
                })
            });

            if (!response.ok) throw new Error('Failed to create subproject');
            
            // Close the form and reset
            setShowAddSubproject(false);
            setNewSubproject({ id: '', numPatients: '' });
            // Refresh subprojects list
            onSubprojectsUpdate();
        } catch (error) {
            console.error('Error creating subproject:', error);
            alert('Failed to create subproject. Please try again.');
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="PIFM-overlay">
            <div className="PIFM-container">
                <div className="PIFM-content">
                    <div className="PIFM-left-panel">
                        <div className="PIFM-project-section">
                            <div className="PIFM-project-header">
                                <FontAwesomeIcon icon={faInfoCircle} className="PIFM-project-icon" />
                                <span className="PIFM-project-title">{project.name}</span>
                                <span className="PIFM-project-title1">#{project.id}</span>

                            </div>

                            <div className="PIFM-metrics-section">
                                <div className="PIFM-overview-section">
                                    <div className="PIFM-overview-card">
                                        <FontAwesomeIcon icon={faUsers} className="PIFM-project-iconB" />
                                        <div className="PIFM-overview-text">
                                            <span>{patients.length}</span>
                                            <span> Patients</span>
                                        </div>
                                    </div>
                                    <div className="PIFM-overview-card">
                                        <FontAwesomeIcon icon={faFolderOpen} className="PIFM-project-iconB" />
                                        <div className="PIFM-overview-text">
                                            <span>{subprojects.filter(sp => sp.project_id !== 'all').length}</span>
                                            <span> Subprojects</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="PIFM-metrics-grid">
                            {Object.entries(totalStats)
                                .sort(([, a], [, b]) => {
                                    // First sort by count
                                    const countDiff = b.count - a.count;
                                    if (countDiff !== 0) return countDiff;
                                    // If counts are equal, sort by percentage
                                    return (b.count / b.total) - (a.count / a.total);
                                })
                                .map(([type, stats], index) => (
                                    <div 
                                        key={type} 
                                        className="PIFM-progress-item"
                                        style={{
                                            order: Math.floor(index / 2) * 2 + (index % 2), // Creates top-down, left-to-right order
                                        }}
                                    >
                                        <div className="PIFM-progress-label">
                                            <span>
                                                <FontAwesomeIcon icon={REPORT_TYPES[type].icon} />
                                                {type}
                                            </span>
                                            <div className="PIFM-progress-stats">
                                                {stats.count}/{stats.total}
                                            </div>
                                        </div>
                                        <div className="PIFM-progress-bar">
                                            <div 
                                                className="PIFM-progress-fill"
                                                style={{ width: `${(stats.count / stats.total) * 100}%` }}
                                            />
                                        </div>
                                    </div>
                            ))}
                        </div>
                    </div>

                    <div className="PIFM-right-panel">
                        <div className="PIFM-subprojects-header">
                        <button className="PIFM-add-button" onClick={() => setShowAddSubproject(true)}>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                            <div className="PIFM-search-container">
                                <input
                                    type="text"
                                    placeholder="Search subprojects..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="PIFM-search-input"
                                />
                            </div>
              
                            <button className="PIFM-close-button" onClick={onClose}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <div className="PIFM-subprojects-content">
                            <div className="PIFM-subprojects-grid">
                                {showAddSubproject && (
                                    <div className="PIFM-subproject-card PIFM-temp-subproject">
                                        <div className="PIFM-add-subproject-form">
                                            <input
                                                type="text"
                                                placeholder="Subproject ID"
                                                value={newSubproject.id}
                                                onChange={(e) => setNewSubproject({ ...newSubproject, id: e.target.value })}
                                                className="PIFM-add-subproject-input"
                                            />
                                            <input
                                                type="number"
                                                placeholder="Number of patients"
                                                value={newSubproject.numPatients}
                                                onChange={(e) => setNewSubproject({ ...newSubproject, numPatients: e.target.value })}
                                                className="PIFM-add-subproject-input"
                                            />
                                            <div className="PIFM-temp-subproject-buttons">
                                                <button 
                                                    className="PIFM-add-subproject-button" 
                                                    onClick={handleAddSubproject}
                                                    disabled={isCreating || !newSubproject.id || !newSubproject.numPatients}
                                                >
                                                    {isCreating ? 'Creating...' : 'Done'}
                                                </button>
                                                <button 
                                                    className="PIFM-cancel-button" 
                                                    onClick={() => {
                                                        setShowAddSubproject(false);
                                                        setNewSubproject({ id: '', numPatients: '' });
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {filteredSubprojects.map(subproject => {
                                    const subprojectPatients = patients.filter(
                                        p => p.project_id === subproject.project_id
                                    );
                                    const stats = getSubprojectStats(subproject);
                                    
                                    return (
                                        <div key={subproject.project_id} className="PIFM-subproject-tile">
                                            <div className="PIFM-subproject-header">
                                                <div className="PIFM-subproject-id">
                                                    {subproject.project_id}
                                                </div>
                                                <div className="PIFM-subproject-stats">
                                                    {subprojectPatients.length} patients
                                                </div>
                                            </div>
                                            <div className="PIFM-progress-grid">
                                                {Object.entries(stats)
                                                    .sort(([, a], [, b]) => {
                                                        // First sort by count
                                                        const countDiff = b.count - a.count;
                                                        if (countDiff !== 0) return countDiff;
                                                        // If counts are equal, sort by percentage
                                                        return (b.count / b.total) - (a.count / a.total);
                                                    })
                                                    .map(([type, stat], index, array) => {
                                                        const percentage = (stat.count / stat.total) * 100;
                                                        const numColumns = 2; // Assuming 2 columns
                                                        const numRows = Math.ceil(array.length / numColumns);
                                                        // Calculate position in column-first order
                                                        const column = Math.floor(index / numRows);
                                                        const row = index % numRows;
                                                        const newIndex = row * numColumns + column;
                                                        
                                                        return (
                                                            <div 
                                                                key={type} 
                                                                className="PIFM-progress-item"
                                                                style={{ order: newIndex }}
                                                            >
                                                                <div className="PIFM-progress-label">
                                                                    <span>
                                                                        <FontAwesomeIcon icon={REPORT_TYPES[type].icon} />
                                                                        {type}
                                                                    </span>
                                                                    <div className="PIFM-progress-stats">
                                                                        {stat.count}/{stat.total}
                                                                    </div>
                                                                </div>
                                                                <div className="PIFM-progress-bar">
                                                                    <div 
                                                                        className="PIFM-progress-fill"
                                                                        style={{ width: `${percentage}%` }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectInfoModal;

import React, { useState, useEffect } from 'react';
import './GRModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDna, faCloudUploadAlt, faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const GRModal = ({ isOpen, onClose, onUploadComplete, platformAccount }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [smoothProgress, setSmoothProgress] = useState(0);

    useEffect(() => {
        let animationFrame;
        if (uploadProgress > smoothProgress) {
            animationFrame = requestAnimationFrame(() => {
                setSmoothProgress(prev => {
                    const next = prev + 1;
                    return next > uploadProgress ? uploadProgress : next;
                });
            });
        }
        return () => cancelAnimationFrame(animationFrame);
    }, [uploadProgress, smoothProgress]);

    const handleFileChange = (uploadedFile) => {
        if (uploadedFile) {
            setFile(uploadedFile);
            setError('');
        } else {
            setFile(null);
            setError('Please select a file');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setError('File is required');
            return;
        }

        setIsUploading(true);
        setError('');
        setUploadProgress(0);
        setSmoothProgress(0);

        const CHUNK_SIZE = 95 * 1024 * 1024; // 95MB chunks
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        const uuid = crypto.randomUUID();

        console.log(`Uploading file in ${totalChunks} chunks`);

        try {
            // Upload chunks
            for (let i = 0; i < totalChunks; i++) {
                const start = i * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, file.size);
                const chunk = file.slice(start, end);
                const chunkFile = new File([chunk], file.name, { type: file.type });

                const formData = new FormData();
                // Important: Add metadata fields before the file
                formData.append('uuid', uuid);
                formData.append('chunkIndex', i.toString());
                formData.append('totalChunks', totalChunks.toString());
                formData.append('fileName', file.name);
                // Add the file last
                formData.append('chunk', chunkFile);

                const progress = Math.round(((i + 1) / totalChunks) * 100);
                console.log(`Uploading chunk ${i + 1}/${totalChunks} (${progress}%)`);
                setUploadProgress(progress);

                const response = await fetch('https://service8.szapfs.org/api/generiskpro/upload-chunk', {
                    method: 'POST',
                    body: formData,
                    credentials: 'include'
                });

                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.error || `Chunk upload failed: ${response.status}`);
                }

                const result = await response.json();
                console.log('Chunk upload result:', result);
            }

            // Complete the upload
            const finalResponse = await fetch('https://service8.szapfs.org/api/generiskpro/complete-upload', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    uuid,
                    fileName: file.name,
                    accountId: platformAccount.id,
                    totalChunks
                })
            });

            if (!finalResponse.ok) {
                const error = await finalResponse.json();
                throw new Error(error.error || 'Failed to complete upload');
            }

            await finalResponse.json();
            console.log('Upload complete');
            onClose();
        } catch (err) {
            console.error('Upload failed:', err);
            setError(err.message);
        } finally {
            setIsUploading(false);
        }
    };

    const renderProgress = () => {
        if (!isUploading) return null;
        return (
            <div className="upload-progress-container">
                <div 
                    className="upload-progress-bar" 
                    style={{ width: `${smoothProgress}%` }} 
                />
            </div>
        );
    };

    if (!isOpen) return null;

    return (
        <div className="gr-upload-modal-overlay">
            <div className="gr-upload-modal">
                <button className="gr-upload-modal-close" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className="oncology-upload-modal-header">
                    <h2>Upload File</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="gr-upload-wrapper">
                        <div className={`gr-upload-option ${file ? 'has-file' : ''}`}>
                            <input
                                type="file"
                                onChange={(e) => handleFileChange(e.target.files[0])}
                                required
                            />
                            <div className="gr-upload-header">
                                <FontAwesomeIcon icon={faDna} className="gr-upload-icon" />
                                <h3 className="gr-upload-title">File</h3>
                            </div>
                            {file ? (
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} className="gr-checkmark" />
                                    <div className="gr-file-selected">{file.name}</div>
                                </>
                            ) : (
                                <div className="gr-upload-cloud">
                                    <FontAwesomeIcon icon={faCloudUploadAlt} className="gr-upload-cloud-icon" />
                                    <p className="gr-upload-text">Click or drag file to upload</p>
                                </div>
                            )}
                        </div>
                        <div className="gr-file-type">VCF file types are accepted</div>
                    </div>

                    {error && <div className="gr-error-message">{error}</div>}

                    {renderProgress()}

                    <button 
                        type="submit" 
                        className="Generiskpro-submit-btn"
                        disabled={isUploading || !file}
                    >
                        {isUploading ? 'Uploading...' : 'Submit Analysis'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default GRModal;

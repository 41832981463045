import React, { useState, useEffect } from 'react';
import './CookieConsent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const hasConsent = localStorage.getItem('generiskproCookieConsent');
        if (!hasConsent) {
            // Slight delay before showing for smooth animation
            const timer = setTimeout(() => setIsVisible(true), 1000);
            return () => clearTimeout(timer);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('generiskproCookieConsent', 'true');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-consent-wrapper">
            <div className="cookie-consent">
                <div className="cookie-icon">
                    <FontAwesomeIcon icon={faCookieBite} />
                </div>
                <div className="cookie-content">
                    <h3>Cookie Notice</h3>
                    <p>We use cookies to enhance your experience and analyze our traffic. By continuing to use this website, you consent to our use of cookies.</p>
                </div>
                <button onClick={handleAccept} className="cookie-accept">
                    Got it!
                </button>
            </div>
        </div>
    );
};

export default CookieConsent;

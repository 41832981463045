import React, { useState, useEffect, useRef } from 'react';
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import './Analytics.css';

function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" >
        <p className="label">{`${payload[0].name}: ${payload[0].value}`}</p>
        <p className="label">{`${payload[1].name}: ${payload[1].value}`}</p>
        <p className="label">{`${payload[2].name}: ${payload[2].value}`}</p>
      </div>
    );
  }
  return null;
}

function Analytics() {
  const [cpuData, setCpuData] = useState([]);
  const [memoryData, setMemoryData] = useState([]);
  const [requestsPerSecondData, setRequestsPerSecondData] = useState([]);
  const [diskUsageData, setDiskUsageData] = useState([]);
  const [totalSystemMemory, setTotalSystemMemory] = useState(0); 
  const [processNames, setProcessNames] = useState([]);
  const colors = { app: '#20558a', aioperations: '#2596be',  'observing-server-functions': '#2596be', aioperations: '#20558a', aioperations: '#2596be',  'Monit ': '#2596be'  };
  const maxMemoryMB = 10000; 
  const bytesToMegabytes = bytes => bytes / 1048576; 
  const getLatestValue = (data, key) => data.length > 0 ? data[data.length - 1][key] : 0;

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const response = await fetch('https://service3.szapfs.org/metrics');
      const json = await response.json();
      const { metrics, requestsPerSecond, diskUsage } = json;
      const currentTime = new Date().getTime(); 
      const cpuDataPoint = { name: currentTime };
      const memoryDataPoint = { name: currentTime };
      const requestsDataPoint = { name: currentTime, RequestsPerSecond: requestsPerSecond };
      const diskUsageDataPoint = { name: currentTime, diskUsage: diskUsage }; 
      metrics.forEach(metric => {
        cpuDataPoint[metric.name] = metric.cpu;
        memoryDataPoint[metric.name] = bytesToMegabytes(metric.memory);
        diskUsageDataPoint[metric.name] = metric.diskUsage;
        requestsDataPoint[metric.name] = metric.requestsPerSecond;
      });
      setCpuData(currentCpuData => [...currentCpuData, cpuDataPoint].slice(-10)); 
      setRequestsPerSecondData(currentRequestsData => [...currentRequestsData, requestsDataPoint].slice(-10));
      setDiskUsageData(currentDiskUsageData => [...currentDiskUsageData, diskUsageDataPoint].slice(-10));
      setMemoryData(currentMemoryData => [...currentMemoryData, memoryDataPoint].slice(-10)); 
      setProcessNames(Array.from(new Set(metrics.map(metric => metric.name))));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleRestart = async () => {
    try {
      await fetch('https://service3.szapfs.org/restart');
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handlePlay = async (processName) => {
    try {
      await fetch(`https://service3.szapfs.org/start}`);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleStop = async (processName) => {
    try {
      await fetch(`https://service3.szapfs.org/stop`);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const [logs, setLogs] = useState([]);
  const logsEndRef = useRef(null);
  useEffect(() => {
    let ws;
    let retries = 0;
    const maxRetries = 3;
  
    const connect = () => {
      ws = new WebSocket('wss://service3.szapfs.org');
  
      ws.onopen = () => {
        console.log('WebSocket connected');
        retries = 0; // Reset retries on successful connection
      };
  
      ws.onmessage = (event) => {
        setLogs(prevLogs => [...prevLogs, event.data]);
      };
  
      ws.onclose = () => {
        if (retries < maxRetries) {
          setTimeout(() => {
            console.log('Attempting to reconnect WebSocket...');
            retries++;
            connect();
          }, 2000 * retries); // Exponential backoff
        } else {
          console.log('WebSocket connection failed after retries');
        }
      };
  
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws.close(); // Trigger onclose event
      };
    };
  
    connect();
  
    return () => {
      ws.close();
    };
  }, []);
  

useEffect(() => {
  logsEndRef.current?.scrollIntoView({ behavior: "smooth" });
}, [logs]);

  return (
    <div className="statpage">
    <div className="Analytics">
    <div className="Optionsbar">
  <div className="server-button-container">
    <button className="serverbutton" onClick={handlePlay}>▶</button>
    <span className="server-button-title">Start</span>
  </div>
  <div className="server-button-container">
    <button className="serverbutton" onClick={handleRestart}>↻</button>
    <span className="server-button-title">Restart</span>
  </div>
  <div className="server-button-container">
    <button className="serverbutton" onClick={handleStop}>◼</button>
    <span className="server-button-title">Stop</span>
  </div>
</div>

      <div className="big">
      <div className="Statistilayout">
      <div  className="Aontainer">
      <span className="metrictitles">CPU Usage (%)</span>
      <ResponsiveContainer width="100%" height={300}>

        <AreaChart className="charting" data={cpuData}>

          <XAxis dataKey="name" hide />
          <YAxis domain={[0, 100]} />
          <Tooltip content={<CustomTooltip />} />
          {processNames.map((processName, index) => (
            <Area
              key={`cpu-${processName}`}
              type="monotone"
              dataKey={processName}
              stroke={colors[processName]}
              fill={colors[processName]}
            />
          ))}
        </AreaChart>                </ResponsiveContainer>

        <div className="metricstracker">
          {processNames.map(processName => (
            <div key={`cpu-box-${processName}`} className="CPU" >
              {`${processName}: ${getLatestValue(cpuData, processName)}%`}
            </div>
          ))}
        </div>
      </div>
      <div className="Aontainer">
      <span className="metrictitles">Memory Usage (MB)</span>
      <ResponsiveContainer width="100%" height={300}>

        <AreaChart  className="charting"  data={memoryData}>
          <XAxis dataKey="name" hide />
          <YAxis domain={[0, maxMemoryMB]}  />
          <Tooltip content={<CustomTooltip />} />
          {processNames.map(processName => (
            <Area
              key={`memory-${processName}`}
              type="monotone"
              dataKey={processName}
              stroke={colors[processName]}
              fill={colors[processName]}
              
            />
          ))}
        </AreaChart>
                </ResponsiveContainer>

        <div className="metricstracker">
          {processNames.map(processName => (
            <div key={`cpu-box-${processName}`} className="CPU" >
{processName} {Math.round(getLatestValue(memoryData, processName) * 1000) / 1000} MB
            </div>
          ))}
        </div>
      </div>
      <div className="Aontainer">
      <span className="metrictitles"> Server/DB Disk Usage (%)</span>
      <ResponsiveContainer width="100%" height={300}>

        <AreaChart  className="charting"  data={diskUsageData}>
          <XAxis dataKey="name" hide />
          <YAxis domain={[0, 100]}  />
          <Tooltip content={<CustomTooltip />} />
          {processNames.map(processName => (
            <Area
              key={`disk-${processName}`}
              type="monotone"
              dataKey={processName}
              stroke={colors[processName]}
              fill={colors[processName]}
              
            />
          ))}
        </AreaChart>
        </ResponsiveContainer>

        <div className="metricstracker">
          {processNames.map(processName => (
            <div key={`cpu-box-${processName}`} className="CPU" >
{processName} {Math.round(getLatestValue(diskUsageData, processName) * 100) / 100} %
            </div>
          ))}
        </div>
      </div>
      <div className="Aontainer">
      <span className="metrictitles"> Server/DB Requests per Second (s)</span>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart  className="charting"  data={requestsPerSecondData}>
          <XAxis dataKey="name" hide />
          <YAxis domain={[0, 100]}  />
          <Tooltip content={<CustomTooltip />} />
          {processNames.map(processName => (
            <Area
              key={`memory-${processName}`}
              type="monotone"
              dataKey={processName}
              stroke={colors[processName]}
              fill={colors[processName]}
            />
          ))}
        </AreaChart>
        </ResponsiveContainer>
        <div className="metricstracker">
          {processNames.map(processName => (
            <div key={`cpu-box-${processName}`} className="CPU" >
{processName} {Math.round(getLatestValue(requestsPerSecondData, processName) * 100) / 100} /s
            </div>
          ))}
        </div>
      </div>
      </div>
      <div className="logs">
        {logs.map((log, index) => (
          <div key={index}>{log}</div>
        ))}
        {/* This is an invisible element at the end of the logs container */}
        <div ref={logsEndRef} />
      </div>
    </div>
    </div>
    </div>
  );
}
export default Analytics;

import React from 'react';
import './ProjectTile.css';

function ProjectTile({ project, onProjectClick }) {
    const sortedReports = [...(project.reports || [])].sort((a, b) => b.active - a.active);
    const totalPatients = project.totalPatients || 0;

    const getProgressWidth = (active, total) => {
        if (total === 0) return 0;
        return (active / total) * 100;
    };

    return (
        <div className="PMAT-container" onClick={() => onProjectClick(project.projectId)}>
            <div className="PMAT-header">
                <span className="PMAT-title1">{project.name}</span>
                <span className="PMAT-title2">{project.projectId}</span>
            </div>
            <div className="PMAT-content">
                {sortedReports.map((report, index) => (
                    <div key={index} className="PMAT-report-item">
                        <div className="PMAT-report-header">
                            <span className="PMAT-report-name">{report.name}</span>
                            <span className="PMAT-report-count">{report.active}/{totalPatients}</span>
                        </div>
                        <div className="PMAT-progress-bar">
                            <div 
                                className="PMAT-progress-fill" 
                                style={{ width: `${getProgressWidth(report.active, totalPatients)}%` }}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="PMAT-bottom">
                <span className="PMAT-total">Total Patients: {totalPatients}</span>
                <span className="PMAT-date">Created: {new Date(project.createdAt).toLocaleDateString()}</span>
            </div>
        </div>
    );
}

export default ProjectTile;

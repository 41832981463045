import React, { useState, useEffect } from 'react';
import OncologyModal from './OncologyModal';
import { usePlatformAccount } from '../usePlatformAccount';
import './OncologyLanding.css';
import logo from '../../2222.png';
import CookieConsent from './CookieConsent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faChartLine, faSignOutAlt, faBell, faUserPlus, faBars, faDna, faSoap, faHandsBubbles, faHouseFloodWater, faWater, faMicroscope, faFilePdf, faCloud, faCloudUpload, faFilePowerpoint, faFile, faFileAlt, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

const API_BASE = 'https://service7.szapfs.org';
const DEFAULT_ACCOUNT_ID = '26962f28-2907-40ea-a91b-695d479f8798';

const OncoriskDashboard = () => {
    const { platformAccount, isLoading: accountLoading, login, signup, logout } = usePlatformAccount();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [error, setError] = useState('');
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSignup, setIsSignup] = useState(false);
    const [reportUrl, setReportUrl] = useState(null);
    const [isFetchingReport, setIsFetchingReport] = useState(false);
    const [jobStatuses, setJobStatuses] = useState({});

    useEffect(() => {
        fetchJobs();
        const interval = setInterval(fetchJobs, 1000);
        return () => clearInterval(interval);
    }, [platformAccount]);

    const fetchJobStatus = async (uuid) => {
        try {
            const response = await fetch(
                `${API_BASE}/api/oncology/status?uuid=${uuid}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = await response.json();
            setJobStatuses(prev => ({
                ...prev,
                [uuid]: data.status
            }));

            // If this is the selected job and status just changed to Done, fetch report
            if (selectedJob?.uuid === uuid && data.status === 'Done') {
                fetchReportUrl();
            }
        } catch (error) {
            console.error('Error fetching job status:', error);
        }
    };

    useEffect(() => {
        // Fetch status for all processed jobs
        jobs.forEach(job => {
            if (job.status === 'processed') {
                fetchJobStatus(job.uuid);
            }
        });

        const interval = setInterval(() => {
            jobs.forEach(job => {
                if (job.status === 'processed') {
                    fetchJobStatus(job.uuid);
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [jobs]);

    const getJobStatus = (job) => {
        if (job.status === 'processing') return 'Generating Report';
        if (job.status === 'uploaded') return 'Uploaded';
        if (job.status === 'processed') {
            const status = jobStatuses[job.uuid];
            if (!status) return 'Checking Status...';
            if (status === 'Done') return 'Completed';
            return status;
        }
        return job.status;
    };

    const getStatusClass = (status) => {
        switch (status.toLowerCase()) {
            case 'generating report':
                return 'generating';
            case 'uploaded':
                return 'uploaded';
            case 'completed':
                return 'completed';
            default:
                return status.toLowerCase();
        }
    };

    const fetchJobs = async () => {
        try {
            // Use default account ID if user is not logged in
            const accountId = platformAccount?.id || DEFAULT_ACCOUNT_ID;
            
            const response = await fetch(
                `${API_BASE}/api/oncology/jobs?accountId=${accountId}`
            );
            if (response.ok) {
                const data = await response.json();
                setJobs(data);
                // Update selected job if it exists in the new data
                if (selectedJob) {
                    const updatedJob = data.find(job => job.uuid === selectedJob.uuid);
                    if (updatedJob && updatedJob.status !== selectedJob.status) {
                        setSelectedJob(updatedJob);
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setError('Failed to load jobs');
        }
    };

    useEffect(() => {
        setReportUrl(null);
        setIsFetchingReport(false);
        if (selectedJob?.status === 'processed') {
            // Initial fetch
            fetchReportUrl();
            // Also check status in case it's already Done
            fetchJobStatus(selectedJob.uuid);
            
            const interval = setInterval(() => {
                fetchJobStatus(selectedJob.uuid);
            }, 1000);
            
            return () => {
                clearInterval(interval);
                setReportUrl(null);
                setIsFetchingReport(false);
            };
        }
    }, [selectedJob?.uuid]);

    const fetchReportUrl = async () => {
        if (!selectedJob?.uuid || isFetchingReport) return;
        
        try {
            setIsFetchingReport(true);
            const response = await fetch(
                `${API_BASE}/api/oncology/report?uuid=${selectedJob.uuid}`
            );
            if (response.ok) {
                const data = await response.json();
                if (data.reportUrl) {
                    setReportUrl(data.reportUrl);
                }
            }
        } catch (error) {
            console.error('Error fetching report URL:', error);
        } finally {
            setIsFetchingReport(false);
        }
    };

    const handleUploadComplete = async (newJob) => {
        setJobs(prevJobs => [newJob, ...prevJobs]);
        setSelectedJob(null); // Clear selection after upload
        await fetchJobs();
    };

    const handleLogout = async () => {
        await logout();
        setShowUserMenu(false);
        setJobs([]);
        setSelectedJob(null);
        setReportUrl(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isSignup) {
                await signup(username, password);
            } else {
                await login(username, password);
            }
            setShowLoginForm(false);
            setUsername('');
            setPassword('');
            setIsSignup(false);
        } catch (error) {
            console.error(isSignup ? 'Signup error:' : 'Login error:', error);
        }
    };

    const formatDate = (timestamp) => {
        return new Date(timestamp).toLocaleString();
    };

    const handleJobClick = (job) => {
        const status = jobStatuses[job.uuid];
        if (status === 'Done') {
            setSelectedJob(job);
        }
    };

    const renderJobs = () => {
        if (!jobs.length) {
            return (
                <div className="oncology-empty-state">
                    <p>Upload a new analysis to get started</p>
                </div>
            );
        }

        return jobs.map((job) => {
            const isReady = jobStatuses[job.uuid] === 'Done';
            return (
                <div
                    key={job.uuid}
                    className={`oncology-job-item ${isReady ? 'ready' : ''} ${selectedJob?.uuid === job.uuid ? 'selected' : ''}`}
                    onClick={() => handleJobClick(job)}
                >
                    <div className="oncology-job-header">
                        <span className="oncology-job-name">
                            {job.original_filename}
                        </span>
                        <span className={`oncology-job-status ${getStatusClass(getJobStatus(job))}`}>
                            {getJobStatus(job)}
                        </span>
                    </div>
                    <div className="oncology-job-details">
                        <span>{formatDate(job.upload_time)}</span>
                    </div>
                </div>
            );
        });
    };

    const renderMainContent = () => {
        if (!selectedJob) {
            return (
                <div className="oncology-empty-state">
                    <h2>Select a job to view details</h2>
                    <p>Or upload a new analysis to get started</p>
                </div>
            );
        }

        if (selectedJob.status !== 'processed') {
            return (
                <div className="oncology-processing-state">
                    <h2>Report Being Generated</h2>
                    <p>Please wait while we process your analysis...</p>
    
                </div>
            );
        }

        if (!reportUrl) {
            return (
                <div className="oncology-loading-state">
                    <h2>Loading Report...</h2>
                    <p>Please wait while we fetch your report...</p>
                </div>
            );
        }

        return renderPdfViewer();
    };

    const renderPdfViewer = () => {
        if (!reportUrl) {
            return null;
        }

        // Use a key to force iframe refresh when URL changes
        return (
            <div className="oncology-pdf-viewer">
                <div className="oncology-loader" />
                <iframe
                    key={reportUrl}
                    src={reportUrl}
                />
            </div>
        );
    };

    return (
        <div className="oncology-dashboard">
            <div className="oncology-sidebar">
                <div className="oncology-logo">
                    <img src={logo} alt="Oncorisk Logo" />
                </div>
                <div className="oncology-controls">
                    {platformAccount ? (
                        <div className="oncology-user-info">
                            <button 
                                className="oncology-user-button"
                                onClick={() => setShowUserMenu(!showUserMenu)}
                            >
                                <div className="oncology-profile-circle">
                                    {platformAccount.username[0].toUpperCase()}
                                </div>
                                <span>{platformAccount.username.charAt(0).toUpperCase() + platformAccount.username.slice(1)}</span>
                            </button>
                            {showUserMenu && (
                                <div className="oncology-user-menu">
                                    <button 
                                        className="oncology-menu-item"
                                        onClick={handleLogout}
                                    >
                                        Log Out
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div 
                            className="oncology-login-btn"
                            onClick={() => setShowLoginForm(true)}
                        >
                            Log In
                        </div>
                    )}
                    <div 
                        className="oncology-upload-btn"
                        onClick={() => platformAccount ? setIsModalOpen(true) : setShowLoginForm(true)}
                    >
                                                <FontAwesomeIcon icon={faCloudUploadAlt} className="outline-icon" />

                        Upload
                    </div>
                </div>
                <div className="oncology-jobs-list">
                    {renderJobs()}
                </div>
            </div>

            <div className="oncology-main-content">
                {renderMainContent()}
            </div>

            {isModalOpen && (
                <OncologyModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onUploadComplete={handleUploadComplete}
                    platformAccount={platformAccount}
                />
            )}

            <CookieConsent />

            {showLoginForm && (
                <div className="Generiskpro-modal-overlay">
                    <div className="Generiskpro-modal">
                        <button 
                            className="Generiskpro-modal-close"
                            onClick={() => {
                                setShowLoginForm(false);
                                setIsSignup(false);
                            }}
                        >
                            ×
                        </button>
                        <div className="Generiskpro-modal-header">
                            <h2>{isSignup ? 'Sign Up' : 'Log In'}</h2>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="Generiskpro-form-group">
                                <label>Username</label>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="Generiskpro-form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            {error && (
                                <div className="Generiskpro-error-message">
                                    {error}
                                </div>
                            )}
                            <button type="submit" className="Generiskpro-submit-btn">
                                {isSignup ? 'Sign Up' : 'Log In'}
                            </button>
                            <div className="Generiskpro-auth-switch">
                                {isSignup ? (
                                    <p>
                                        Already have an account?{' '}
                                        <button 
                                            onClick={() => {
                                                setIsSignup(false);
                                                setUsername('');
                                                setPassword('');
                                                setError('');
                                            }}
                                        >
                                            Log In
                                        </button>
                                    </p>
                                ) : (
                                    <p>
                                        Don't have an account?{' '}
                                        <button 
                                            onClick={() => {
                                                setIsSignup(true);
                                                setUsername('');
                                                setPassword('');
                                                setError('');
                                            }}
                                        >
                                            Sign Up
                                        </button>
                                    </p>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OncoriskDashboard;
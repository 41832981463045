import React, { useState, useEffect } from "react";
import "./Newborn.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFlask, faDna, faBaby, faTooth, faDroplet, faEllipsisV, faLayerGroup,
  faChevronCircleRight,
  faCheck,
  faTrash,
  faAdd
} from '@fortawesome/free-solid-svg-icons';

const NewbornModal = ({ isOpen, onClose, onSave, initialData, currentFields }) => {
  const [selectedGenes, setSelectedGenes] = useState([]);
  const [selectedClinician, setSelectedClinician] = useState("Dr. Gözde YEŞİL SAYIN");
  const [reportData, setReportData] = useState(null);
  const [filteredReportData, setFilteredReportData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [diseases, setDiseases] = useState([]);
  const [filteredDiseases, setFilteredDiseases] = useState([]);
  const [qcData, setQcData] = useState({
    targetRegionDepth: "",
    targetRegion20x: "",
    clinicalInfo: ""
  });

  // Fetch diseases once when component mounts
  useEffect(() => {
    fetch('https://service3.szapfs.org/getWGSDiseases')
      .then(res => res.json())
      .then(data => setDiseases(data))
      .catch(err => console.error('Error fetching diseases:', err));
  }, []);


  const handleDeleteSavedRow = (index) => {
    const updatedSavedRows = [...savedRows];
    updatedSavedRows.splice(index, 1); // Remove the row at the given index
    setSavedRows(updatedSavedRows);
  };
  
  const [newRows, setNewRows] = useState([
    {
      Genes: "",
      HGVSc: "",
      HGVSp: "",
      Zygosity: "",
      ClinVar_CLNSIG: "",
      Inheritances: "",
      Disease: "",
    },
  ]);
    const [savedRows, setSavedRows] = useState([]);


  // Handle updating a row's field
  const handleRowChange = (index, field, value) => {
    const updatedRows = [...newRows];
    updatedRows[index][field] = value;
    setNewRows(updatedRows);

    // Only filter diseases if the field is Disease and value length > 2
    if (field === 'Disease' && value.length > 2) {
      const filtered = diseases.filter(disease => 
        disease.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDiseases(filtered);
    } else if (field === 'Disease') {
      setFilteredDiseases([]);
    }
  };
  
  const handleSaveRow = () => {
    const rowToSave = newRows[0];
    let hasError = false;
  
    if (!rowToSave.Genes) {
      const geneHighlight = document.querySelector('.gene-container');
      if (geneHighlight) {
        geneHighlight.style.border = "1px solid red";
        setTimeout(() => {
          geneHighlight.style.border = "";
        }, 1000);
      }
      hasError = true;
    }
  
    if (rowToSave.Disease && !diseases.includes(rowToSave.Disease)) {
      const diseaseHighlight = document.querySelector('.disease-container');
      if (diseaseHighlight) {
        diseaseHighlight.style.border = "1px solid red";
        setTimeout(() => {
          diseaseHighlight.style.border = "";
        }, 1000);
      }
      hasError = true;
    }
    
    
  
    // Stop if there are errors
    if (hasError) {
      return;
    }
  
    // If no errors, save the row
    setSavedRows([...savedRows, rowToSave]);
    setNewRows([{ Genes: "", HGVSc: "", HGVSp: "", Zygosity: "", ClinVar_CLNSIG: "", Inheritances: "", Disease: "" }]);
  };
  
  
  
  
  
  const goToPage = (page) => {
    setCurrentPage(page);
  };
  
  useEffect(() => {
    if (isOpen && initialData) {
      // No need to JSON.parse since initialData is already an object
      setSelectedGenes(initialData.genes || []);
      setSelectedClinician(initialData.clinician || "Dr. Gözde YEŞİL SAYIN");
      setSavedRows((initialData.variants || []).map(v => ({
        Genes: v.gene || "",
        HGVSc: v.hgvsc || "",
        HGVSp: v.hgvsp || "",
        Zygosity: v.zygosity || "",
        ClinVar_CLNSIG: v.pathogenicity || "",
        Inheritances: v.inheritance || "",
        Disease: v.disease || ""
      })));
      // Initialize QC data from initialData
      setQcData({
        targetRegionDepth: initialData.qc?.targetRegionDepth || "",
        targetRegion20x: initialData.qc?.targetRegion20x || "",
        clinicalInfo: initialData.qc?.clinicalInfo || ""
      });
    }
  }, [isOpen, initialData]);
  

  useEffect(() => {
    if (isOpen && currentFields?.Preset) {
      // Fetch the newborn report when modal opens
      fetch('https://service3.szapfs.org/grabnewbornreport', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Preset: currentFields.Preset }),
      })
        .then((response) => response.json())
        .then((data) => {
          setReportData(data);
          setFilteredReportData(data); // Initially, show all data
          console.log(currentFields)


        })
        .catch((error) => console.error('Error fetching report data:', error));
    }
  }, [isOpen, currentFields]);

  useEffect(() => {
    if (reportData) {
      const query = searchQuery.toLowerCase();
      const filtered = reportData.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(query)
        )
      );
      setFilteredReportData(filtered);
    }
  }, [searchQuery, reportData]);

  const handleGeneClick = (gene) => {
    if (!selectedGenes.includes(gene)) {
      setSelectedGenes([...selectedGenes, gene]);
    } else {
      setSelectedGenes(selectedGenes.filter((g) => g !== gene));
    }
  };

  const handleClinicianChange = (event) => {
    setSelectedClinician(event.target.value);
  };
  const formattedOutput = {
    genes: selectedGenes.filter(gene => !gene.startsWith("!")),
    clinician: selectedClinician,
    variants: savedRows.map(row => ({
      gene: row.Genes,
      hgvsc: row.HGVSc,
      hgvsp: row.HGVSp,
      zygosity: row.Zygosity,
      pathogenicity: row.ClinVar_CLNSIG,
      inheritance: row.Inheritances,
      disease: row.Disease
    })),
    qc: {
      targetRegionDepth: qcData.targetRegionDepth,
      targetRegion20x: qcData.targetRegion20x,
      clinicalInfo: qcData.clinicalInfo
    }
  };

  

  
  return (
    <div className="newborn-modal-overlay">
      <div className="newborn-modal-container">
        <div className="NMT">
          <div className="NewbornProfile">
            <div className="NewbLeft">
              <div className="NewbLC">
                <FontAwesomeIcon icon={faBaby} />
              </div>
            </div>
            <div className="NCF">
              {currentFields?.PatientFirstName && (
                <div className="NCFName">{currentFields.PatientFirstName}</div>
              )}
              {currentFields?.PatientGender && (
                <div className="NCFSubName">{currentFields.PatientGender}</div>
              )}
              {currentFields?.PatientDateOfBirth && (
                <div className="NCFSubName1">{currentFields.PatientDateOfBirth}</div>
              )}
            </div>
            <div className="NCFR">
              <div className="NCFRt">
                Signing Clinician
                <div>
                  <select
                    id="signingClinician"
                    className="NCFRtd"
                    value={selectedClinician}
                    onChange={handleClinicianChange}
                  >
                    <option value="Dr. Gözde YEŞİL SAYIN">Dr. Gözde YEŞİL SAYIN</option>
                    <option value="Dr. Ahmet YEŞİLYURT">Dr. Ahmet YEŞİLYURT</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            className="newborn-modal-button"
            onClick={() => onSave(formattedOutput)}
          >
            X
          </div>
        </div>

        <div className="NMS">
          <input 
            type="text" 
            className="NMSsearch" 
            placeholder="Search..." 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="NMM-wrapper">
          
    <button
      className="arrow-btn left-arrow"
      onClick={() => goToPage(currentPage === 1 ? 2 : 1)}
    >
      &lt;
    </button>
  <div className="NMM">
    <div
      className={`NMM-content newbornFade`}
      key={currentPage} // Force re-render on page switch
    >
      {currentPage === 1 && (
        <div className="newborn-report-list">
          {/* Page 1 Content */}
          {filteredReportData && (
            Object.values(
              filteredReportData.reduce((groups, row) => {
                if (!groups[row.Genes]) {
                  groups[row.Genes] = {
                    Genes: row.Genes,
                    HGVSc: row.HGVSc,
                    HGVSp: row.HGVSp,
                    Zygosity: row.Zygosity,
                    Diseases: {},
                  };
                }
                if (!groups[row.Genes].Diseases[row.Disease]) {
                  groups[row.Genes].Diseases[row.Disease] = row.Inheritances;
                }
                return groups;
              }, {})
            ).map((group, index) => (
              <div
                className="gene-group"
                key={index}
                onClick={() => handleGeneClick(group.Genes)}
                style={{
                  border: selectedGenes.includes(group.Genes)
                    ? "3px solid #2595be7e"
                    : "3px solid white",
                }}
              >
                <div className="GRN">
                  {selectedGenes.includes(group.Genes) && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="floating-checkmark"
                    />
                  )}
                  <div className="grn-title">
                    <h3>{group.Genes}</h3>
                  </div>
                  <div className="grn-details">
                    <small className="grn-detail-title">HGVSc</small>
                    <div className="grn-detail-item">
                      <div className="grn-detail-value">{group.HGVSc}</div>
                    </div>
                    <small className="grn-detail-title">HGVSp</small>
                    <div className="grn-detail-item">
                      <div className="grn-detail-value">{group.HGVSp}</div>
                    </div>
                    <small className="grn-detail-title">Zygosity</small>
                    <div className="grn-detail-item">
                      <div className="grn-detail-value">{group.Zygosity}</div>
                    </div>
                  </div>
                </div>
                <div className="GRNR">
                  <div className="NWB-container">
                    {Object.entries(group.Diseases).map(([disease, inheritances]) => (
                      <div className="NWB-box" key={disease}>
                        <strong>{disease}</strong>
                        {!inheritances.includes("No matched") &&
                          !inheritances.includes("Not provided") && (
                            <div className="NWB-inheritances">
                              {inheritances}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
{currentPage === 2 && (
  <div className="NEX-details">
    <div className="NEX-detailstop">
      <div className="NEX-detailsleft">Clinician Fields</div>
    </div>

    <div className="NEX-temp-row">
  <div className="NEX-row-input">
    {Object.keys(newRows[0] || {}).map((field) => (
      <div key={field} className="NEX-field-wrapper">
        <span className="NEX-field-label">
          {field === "ClinVar_CLNSIG" ? "Pathogenicity" : field}
        </span>
        {field === "Genes" ? (
  <div className="NEX-field-input gene-container">
  <input
    type="text"
    placeholder="Mandatory"
    value={newRows[0]?.[field] || ""}
    onChange={(e) => handleRowChange(0, field, e.target.value)}
    className="NEX-stylinginput"
  />
</div>
        ) : field === "Zygosity" ? (
          <select
            value={newRows[0]?.[field] || ""}
            onChange={(e) => handleRowChange(0, field, e.target.value)}
            className="NEX-field-input"
          >
            <option value="">Select</option>
            <option value="Homozygous">Homozygous</option>
            <option value="Heterozygous">Heterozygous</option>
            <option value="Hemizygous">Hemizygous</option>
            <option value="Compound Heterozygous">Compound Heterozygous</option>
          </select>
        ) : field === "ClinVar_CLNSIG" ? (
          <select
            value={newRows[0]?.[field] || ""}
            onChange={(e) => handleRowChange(0, field, e.target.value)}
            className="NEX-field-input"
          >
            <option value="">Select</option>
            <option value="pathogenic">Pathogenic</option>
            <option value="likely pathogenic">Likely Pathogenic</option>
            <option value="uncertain significance">Uncertain Significance</option>
            <option value="benign">Benign</option>
            <option value="likely benign">Likely Benign</option>
            <option value="pathogenic&risk_factor">Pathogenic & Risk Factor</option>
            <option value="pathogenic/likely_pathogenic">Pathogenic/Likely Pathogenic</option>
          </select>
        ) : field === "Inheritances" ? (
          <select
            value={newRows[0]?.[field] || ""}
            onChange={(e) => handleRowChange(0, field, e.target.value)}
            className="NEX-field-input"
          >
            <option value="">Select</option>
            <option value="autosomal recessive">Autosomal Recessive</option>
            <option value="autosomal dominant">Autosomal Dominant</option>
            <option value="x-linked">X-linked</option>
            <option value="x-linked dominant">X-linked Dominant</option>
            <option value="x-linked recessive">X-linked Recessive</option>
            <option value="digenic dominant">Digenic Dominant</option>
            <option value="digenic recessive">Digenic Recessive</option>
            <option value="pseudoautosomal dominant">Pseudoautosomal Dominant</option>
            <option value="pseudoautosomal recessive">Pseudoautosomal Recessive</option>
            <option value="multifactorial">Multifactorial</option>
            <option value="somatic mutation">Somatic Mutation</option>
            <option value="isolated cases">Isolated Cases</option>
            <option value="inheritance not provided by omim">Inheritance Not Provided by OMIM</option>
          </select>
        ) : field === "Disease" ? ( 
        
<div className="NEX-field-input disease-container" style={{ position: 'relative' }}>
  <input
    type="text"
    value={newRows[0]?.[field] || ""}
    onChange={(e) => handleRowChange(0, field, e.target.value)}
    placeholder="Mandatory"
    className="NEX-stylinginput"
  />
            {filteredDiseases.length > 0 && (
              <div className="disease-dropdown">
                {filteredDiseases.map((disease, i) => (
                  <div
                    key={i}
                    className="disease-option"
                    onClick={() => {
                      handleRowChange(0, 'Disease', disease);
                      setFilteredDiseases([]);
                    }}
                  >
                    {disease}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <input
            type="text"
            value={newRows[0]?.[field] || ""}
            onChange={(e) => handleRowChange(0, field, e.target.value)}
            className="NEX-field-input"
          />
        )}
        <span className="NEX-field-example">
          {field === "Genes"
            ? "TYR"
            : field === "HGVSc"
            ? "c.1858C>T"
            : field === "HGVSp"
            ? "p.Arg620Ter"
            : field === "Zygosity"
            ? "Heterozygous"
            : field === "ClinVar_CLNSIG"
            ? "Pathogenic"
            : field === "Inheritances"
            ? "Autosomal Recessive"
            : field === "Disease"
            ? "Marfan syndrome"
            : ""}
        </span>
      </div>
    ))}
    <button
      onClick={() => handleSaveRow(0)}
      className="NEX-save-row-btn"
    >
      <FontAwesomeIcon icon={faAdd} />
    </button>
  </div>
</div>

<div
  className="NEX-saved-rows-list"
  style={{
    height: currentFields?.ReportType?.includes("Exome") ? "50%" : "82%",
  }}
>     {savedRows.map((row, index) => (
          <div key={index} className="NEX-saved-row">
                <div className="NEX-row-item">
              <button
                onClick={() => handleDeleteSavedRow(index)}
                className="NEX-trash-btn"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
            <div className="NEX-row-item">
              <div className="NEX-row-title">Gene</div>
              <div className="NEX-row-value">{row.Genes}</div>
            </div>
            <div className="NEX-row-item">
              <div className="NEX-row-title">HGVSc</div>
              <div className="NEX-row-value">{row.HGVSc}</div>
            </div>
            <div className="NEX-row-item">
              <div className="NEX-row-title">HGVSp</div>
              <div className="NEX-row-value">{row.HGVSp}</div>
            </div>
            <div className="NEX-row-item">
              <div className="NEX-row-title">Zygosity</div>
              <div className="NEX-row-value">{row.Zygosity}</div>
            </div>
            <div className="NEX-row-item">
              <div className="NEX-row-title">ClinVar_CLNSIG</div>
              <div className="NEX-row-value">{row.ClinVar_CLNSIG}</div>
            </div>
            <div className="NEX-row-item">
              <div className="NEX-row-title">Inheritances</div>
              <div className="NEX-row-value">{row.Inheritances}</div>
            </div>
            <div className="NEX-row-item">
              <div className="NEX-row-title">Disease</div>
              <div className="NEX-row-value">{row.Disease}</div>
            </div>
        
          </div>
        ))}
        
</div>



<div
  className="QC-section"
  style={{
    height: currentFields?.ReportType?.includes("Exome") ? "25%" : "0%",
  }}
>
  {currentFields?.ReportType?.includes("Exome") && (
    <div className="qc-content">
      <div className="qc-left">
        <div className="qc-field">
          <label>Average Sequencing Depth of Target Region:</label>
          <input 
            type="text" 
            className="qc-input-small" 
            value={qcData.targetRegionDepth}
            onChange={(e) => setQcData({...qcData, targetRegionDepth: e.target.value})}
          />
        </div>
        <div className="qc-field">
          <label>Target Region 20X Coverage (%):</label>
          <input 
            type="text" 
            className="qc-input-small" 
            value={qcData.targetRegion20x}
            onChange={(e) => setQcData({...qcData, targetRegion20x: e.target.value})}
          />
        </div>
      </div>
      <div className="qc-right">
        <div className="qc-field-large">
          <label>Clinical Information:</label>
          <textarea 
            className="qc-input-large" 
            value={qcData.clinicalInfo}
            onChange={(e) => setQcData({...qcData, clinicalInfo: e.target.value})}
          />
        </div>
      </div>
    </div>
  )}
</div>
  </div>
)}



    </div>
  </div>
    <button
      className="arrow-btn right-arrow"
      onClick={() => goToPage(currentPage === 1 ? 2 : 1)}
    >
      &gt;
    </button>
</div>





 

        <div className="NMB">
          <div className="newborn-modal-input">
          {selectedGenes.length > 0
  ? selectedGenes
      .filter((gene) => !gene.startsWith("!") && !/\[.*?\]/.test(gene)) // Exclude genes with [] and those starting with "!"
      .map((gene, index) => (
        <span
          key={index}
          className="NWBgeneitem"
          onClick={() =>
            setSelectedGenes(selectedGenes.filter((g) => g !== gene))
          } // Remove the clicked gene
          style={{ cursor: "pointer" }} // Indicate clickability
        >
          {gene}
          {index < selectedGenes.filter((g) => !g.startsWith("!") && !/\[.*?\]/.test(g)).length - 1 &&
            " "}
        </span>
      ))
  : ""}


          </div>
        </div>
      </div>
    </div>
  );
};

export default NewbornModal;

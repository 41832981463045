import React, { useEffect, useRef, useState, useCallback } from 'react';
import { pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { ZoomIn, ZoomOut, RotateCw } from 'lucide-react';
import './ReportView.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const BUFFER_PAGES = 5; // Pages to load before and after visible range

const PDFViewer = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1.5);
  const { uuid } = useParams();
  const pdfUrl = `https://service3.szapfs.org/api/pdf/${uuid}`;
  const [pdfDoc, setPdfDoc] = useState(null);
  const containerRef = useRef(null);
  const pagesCache = useRef([]); // Array to hold all page placeholders or canvases

  // Load PDF Document
  useEffect(() => {
    const loadPDF = async () => {
      try {
        const loadingTask = pdfjs.getDocument({
          url: pdfUrl,
          withCredentials: true,
        });
        const pdf = await loadingTask.promise;
        setPdfDoc(pdf);

        // Initialize placeholders for all pages
        pagesCache.current = Array.from({ length: pdf.numPages }, () => null);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    loadPDF();
  }, [pdfUrl]);

  // Render a specific page when needed
  const renderPage = useCallback(
    async (pageNumber) => {
      if (!pdfDoc || pagesCache.current[pageNumber - 1]) return;

      try {
        const page = await pdfDoc.getPage(pageNumber);
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement('canvas');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const context = canvas.getContext('2d');
        await page.render({ canvasContext: context, viewport }).promise;

        // Cache the rendered canvas
        pagesCache.current[pageNumber - 1] = canvas;
        // Force update to reflect changes
        setPdfDoc((doc) => ({ ...doc }));
      } catch (error) {
        console.error(`Error rendering page ${pageNumber}:`, error);
      }
    },
    [pdfDoc, scale]
  );

  // Update visible pages and trigger rendering
  useEffect(() => {
    const updateVisiblePages = () => {
      if (!containerRef.current || !pdfDoc) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const pageHeight = 841 * scale; // A4 height in px
      const scrollTop = containerRef.current.scrollTop;

      const startPage = Math.max(
        1,
        Math.floor(scrollTop / pageHeight) - BUFFER_PAGES
      );
      const endPage = Math.min(
        pdfDoc.numPages,
        Math.ceil((scrollTop + containerRect.height) / pageHeight) +
          BUFFER_PAGES
      );

      for (let i = startPage; i <= endPage; i++) {
        renderPage(i);
      }
    };

    containerRef.current.addEventListener('scroll', updateVisiblePages);
    updateVisiblePages(); // Initial load
    return () =>
      containerRef.current?.removeEventListener('scroll', updateVisiblePages);
  }, [pdfDoc, renderPage, scale]);

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.25, 3));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.25, 0.5));
  };

  const handleReset = () => {
    setScale(1.5);
  };

  return (
    <div className="pdf-viewer">
      <div className="pdf-controls">
        <button onClick={handleZoomOut} className="control-button" title="Zoom Out">
          <ZoomOut className="icon" />
        </button>
        <span className="scale-display">{Math.round(scale * 100)}%</span>
        <button onClick={handleZoomIn} className="control-button" title="Zoom In">
          <ZoomIn className="icon" />
        </button>
        <button onClick={handleReset} className="control-button" title="Reset Zoom">
          <RotateCw className="icon" />
        </button>
      </div>

      <div className="pdf-container" ref={containerRef}>
        {loading && <p className="loading-message">Loading PDF...</p>}
        {error && <p className="error-message">Error: {error}</p>}
        {pdfDoc &&
          pagesCache.current.map((canvas, index) => (
            <div key={index} className="pdf-page-wrapper">
              {canvas ? (
                <canvas
                  ref={(el) => {
                    if (el && !el.childNodes.length) {
                      el.appendChild(canvas);
                    }
                  }}
                />
              ) : (
                <div className="loading-placeholder">Loading...</div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PDFViewer;

import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import './ProjectAccountAssignment.css';

function ProjectAccountAssignment({ position, onClose, patient, onAssign }) {
    const modalRef = useRef(null);
    const [appUsers, setAppUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isFading, setIsFading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const fetchAppUsers = async () => {
            try {
                const response = await fetch('https://service9.szapfs.org/app-users');
                if (!response.ok) throw new Error('Failed to fetch app users');
                const data = await response.json();
                setAppUsers(data);
                
                // If patient has Account_IDs, find and select those users
                if (patient.Account_ID) {
                    const accountIds = patient.Account_ID.split(',').map(id => parseInt(id.trim()));
                    const currentUsers = data.filter(user => accountIds.includes(user.User_ID));
                    setSelectedUsers(currentUsers);
                }
            } catch (error) {
                console.error('Error fetching app users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAppUsers();
    }, [patient.Account_ID]);

    useEffect(() => {
        // Fade in effect
        setTimeout(() => setIsVisible(true), 50);

        // Click outside handler
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClose = () => {
        setIsFading(true);
        setTimeout(() => {
            onClose();
        }, 150);
    };

    const handleAssign = async () => {
        try {
            const userIds = selectedUsers.map(user => user.User_ID).join(',');
            
            const response = await fetch('https://service9.szapfs.org/patient/assign-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    patientId: patient.patient_id,
                    userId: userIds
                })
            });

            if (!response.ok) {
                throw new Error('Failed to assign account');
            }

            await onAssign(patient, userIds);
            handleClose();
        } catch (error) {
            console.error('Error assigning account:', error);
            // You might want to show an error message to the user here
        }
    };

    const removeUser = (userToRemove) => {
        setSelectedUsers(selectedUsers.filter(user => user.User_ID !== userToRemove.User_ID));
    };

    const addUser = (userToAdd) => {
        if (!selectedUsers.find(user => user.User_ID === userToAdd.User_ID)) {
            setSelectedUsers([...selectedUsers, userToAdd]);
        }
    };

    const getInitials = (firstName, lastName) => {
        return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
    };

    const filteredUsers = appUsers.filter(user => {
        const isNotSelected = !selectedUsers.find(selected => selected.User_ID === user.User_ID);
        const matchesSearch = (
            user.First_Name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.Last_Name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.Email?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return isNotSelected && matchesSearch;
    });

    const calculatePosition = () => {
        const modalWidth = 35 * window.innerHeight / 100; // 35vh in pixels
        const modalHeight = 500; // approximate max height

        let top = Math.min(Math.max(position.top, 10), window.innerHeight - modalHeight);
        let left = Math.min(Math.max(position.left, 10), window.innerWidth - modalWidth - 10);

        return { top, left };
    };

    const pos = calculatePosition();

    return (
        <div className={`PMPAS-modal-overlay ${isVisible ? 'visible' : ''}`}>
            <div 
                ref={modalRef}
                className={`PMPAS-modal ${isFading ? 'PMPFadeaway' : ''} ${isVisible ? 'PMPFadein' : ''}`} 
                style={{
                    top: `${pos.top}px`,
                    left: `${pos.left}px`,
                }}
            >
                <div className="PMPAS-header">
                    <span>Assign App Account</span>
                    <FontAwesomeIcon 
                        icon={faTimes} 
                        className="PMPAS-close"
                        onClick={handleClose}
                    />
                </div>
                
                <div className="PMPAS-patient-info">
                    <div className="PMPAS-name">{patient.name}</div>
                    <div className="PMPAS-details">
                        <span>{patient.patient_id}</span>
                        <span>{patient.gender}</span>
                    </div>
                </div>

                {selectedUsers.length === 0 && (
                    <div className="PMPAS-unassigned">
                        <span>No Account Assigned</span>
                    </div>
                )}

                {selectedUsers.length > 0 && (
                    <div className="PMPAS-current-assignment">
                        <div className="PMPAS-current-header">
                            <span>Currently Assigned To</span>
                            <span>{selectedUsers.length} Account{selectedUsers.length !== 1 ? 's' : ''}</span>
                        </div>
                        <div className="PMPAS-current-users">
                            {selectedUsers.map(user => (
                                <div key={user.User_ID} className="PMPAS-current-user">
                                    <FontAwesomeIcon 
                                        icon={faXmark} 
                                        className="PMPAS-remove-user"
                                        onClick={() => removeUser(user)}
                                    />
                                    <div className="PMPAS-user-avatar">
                                        {user.profile_picture ? (
                                            <img 
                                                src={user.profile_picture.startsWith('http') ? 
                                                    user.profile_picture : 
                                                    `https://service3.szapfs.org/profile_pics/${user.profile_picture}`}
                                                alt={`${user.First_Name} ${user.Last_Name}`}
                                            />
                                        ) : (
                                            <div className="PMPAS-user-initial">
                                                {getInitials(user.First_Name, user.Last_Name)}
                                            </div>
                                        )}
                                    </div>
                                    <div className="PMPAS-user-info">
                                        <div className="PMPAS-user-name">
                                            {user.First_Name} {user.Last_Name}
                                        </div>
                                        <div className="PMPAS-user-email">{user.Email}</div>
                                        <div className="PMPAS-user-gender">{user.Sex}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="PMPAS-search">
                    <FontAwesomeIcon icon={faSearch} className="PMPAS-search-icon" />
                    <input 
                        type="text"
                        placeholder="Search users..." 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div className="PMPAS-users-list">
                    {loading ? (
                        <div className="PMPAS-loading">Loading users...</div>
                    ) : (
                        filteredUsers.map(user => (
                            <div 
                                key={user.User_ID}
                                className="PMPAS-user-item"
                                onClick={() => addUser(user)}
                            >
                                <div className="PMPAS-user-avatar">
                                    {user.profile_picture ? (
                                        <img 
                                            src={user.profile_picture.startsWith('http') ? 
                                                user.profile_picture : 
                                                `https://service3.szapfs.org/profile_pics/${user.profile_picture}`}
                                            alt={`${user.First_Name} ${user.Last_Name}`}
                                        />
                                    ) : (
                                        <div className="PMPAS-user-initial">
                                            {getInitials(user.First_Name, user.Last_Name)}
                                        </div>
                                    )}
                                </div>
                                <div className="PMPAS-user-info">
                                    <div className="PMPAS-user-name">
                                        {user.First_Name} {user.Last_Name}
                                    </div>
                                    <div className="PMPAS-user-email">{user.Email}</div>
                                    <div className="PMPAS-user-gender">{user.Sex}</div>
                                </div>
                            </div>
                        ))
                    )}
                </div>

                <button 
                    className="PMPAS-button" 
                    onClick={handleAssign}
                >
                    Assign {selectedUsers.length > 0 ? `(${selectedUsers.length})` : ''}
                </button>
            </div>
        </div>
    );
}

export default ProjectAccountAssignment;

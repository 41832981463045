import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './Login';
import Register from './Register';
import Dashboard from './Dashboard';
import PatientActivation from './patient-activation';
import SetPassword from './SetPassword';
import Translate from './Translate';
import Scanner from './Orders/Scanner.js';
import Filetransfer from './filetransfer/Filetransfer.js';
import PrivacyPolicy from './PrivacyPolicy';
import LandingPage from './LandingPage';
import OncoriskDashboard from './PlatformTools/Oncology/OncologyLanding';
import GeneriskDashboard from './PlatformTools/Generiskpro/GRLanding';
import ReportView from './ReportViewer/ReportView';

function App() {
    return (
        <Router>
            <Routes>
                {/* Public routes */}
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/oncoriskpro" element={<OncoriskDashboard />} />
                <Route path="/generiskpro" element={<GeneriskDashboard />} />
                <Route path="/babygenepro" element={<GeneriskDashboard />} />
                <Route path="/report/:uuid" element={<ReportView />} />

                {/* Protected routes */}
                <Route path="/dashboard" element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
                <Route path="/filetransfer1" element={
                    <ProtectedRoute>
                        <Filetransfer />
                    </ProtectedRoute>
                } />
                <Route path="/translate/:lang" element={<Translate />} />
                <Route path="/ScanEx" element={<Scanner />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                {/* Add any other protected routes here */}
            </Routes>
        </Router>
    );
}

const NavigateToDashboardOrLogin = () => {
    const token = Cookies.get('token');
    return token ? <Navigate to="/dashboard" /> : <Login />;
};

const ProtectedRoute = ({ children }) => {
    const token = Cookies.get('token');

    if (!token) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default App;